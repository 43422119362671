import {
  //
  TitlesKeys,
  UiKeys,
  CertificatesKiwaDailyKeys,
  CertificatesKiwaWeeklyDeviations,
  CertificatesKiwaWeeklyFarms,
  CertificatesKiwaWeeklyRecognitions,
  CertificatesWelfareKeys,
  FarmEventsKeys,
  FarmReportKeys,
  FarmStablesKeys,
  VriKeys,
  InvoicesKeys,
  GeneticKeys,
  MovementEventsKeys,
  ServiceKeys,
  FarmHomeKeys,
  UploadsKeys,
} from '../keys';
import { IntlKeys } from '../keys';

/** Always check if number of lines match
 * export on line 40
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export const nl = {
  // common
  [IntlKeys.ok]: 'Ok',
  [IntlKeys.messageId]: 'Bericht ID',
  [IntlKeys.fromTo]: 'vanaf {from} t/m {to}',
  [IntlKeys.dateRange]: '{start} - vanaf {from} t/m {to}',
  [IntlKeys.date]: 'Datum',
  [IntlKeys.of]: 'van',
  [IntlKeys.pig]: 'Varken',
  [IntlKeys.pigs]: 'Varkens',
  [IntlKeys.hideDetails]: 'Verberg Details',
  [IntlKeys.moreDetails]: 'Meer Details',
  [IntlKeys.cancel]: 'Annuleren',
  [IntlKeys.save]: 'Opslaan',
  [IntlKeys.apply]: 'Toepassen',
  [IntlKeys.accept]: 'Accepteren',
  [IntlKeys.more]: 'Meer',
  [IntlKeys.show]: 'Tonen',
  [IntlKeys.close]: 'Sluiten',
  [IntlKeys.back]: 'Terug',
  [IntlKeys.return]: 'Keer terug',
  [IntlKeys.submit]: 'Verzenden',
  [IntlKeys.approve]: 'Goedkeuren',
  [IntlKeys.reject]: 'Weigeren',
  [IntlKeys.delete]: 'Verwijderen',
  [IntlKeys.download]: 'Download',
  [IntlKeys.request]: 'Verzoek',
  [IntlKeys.synchronize]: 'Synchroniseren',
  [IntlKeys.success]: 'Success',
  [IntlKeys.error]: 'Error',
  [IntlKeys.status]: 'Status',
  [IntlKeys.statusUpdatedBy]: 'Status updated by',
  [IntlKeys.statusUpdatedAt]: 'Status updated at',
  [IntlKeys.deleted]: 'Deleted',
  [IntlKeys.genericSuccessMsg]: 'Alles is goed uitgevoerd ...',
  [IntlKeys.genericErrorMsg]: 'Er is iets fout gegaan...',
  [IntlKeys.continueEditing]: 'Doorgaan met bewerken',
  [IntlKeys.leaveWOSaving]: 'Vertrek zonder op te slaan',
  [IntlKeys.leavePage]: 'Verlaat Pagina',
  [IntlKeys.areYouSure]: 'Weet u het zeker?',
  [IntlKeys.unsavedChanges]: 'U heeft niet-opgeslagen wijzigingen',
  [IntlKeys.unsavedDataWarning]: 'Alle niet-opgeslagen gegevens gaan verloren',
  [IntlKeys.dataSaved]: 'Alle gegevens zijn opgeslagen',
  [IntlKeys.lastModified]: 'Laatst gewijzigd',
  [IntlKeys.lastModifiedBy]: 'Laatst gewijzigd: {date} op {username}',
  [IntlKeys.maximumCapacity]: 'Aantal plaatsen',
  [IntlKeys.selectLocation]: 'Selecteer locatie',
  [IntlKeys.selectYourLocation]: 'Kies uw locatie',
  [IntlKeys.selectUserPassport]: 'Selecteer gebruikerspaspoort',
  [IntlKeys.id]: 'Id',
  [IntlKeys.name]: 'Naam',
  [IntlKeys.email]: 'E-mail',
  [IntlKeys.kvk]: 'KVK',
  [IntlKeys.ubn]: 'UBN',
  [IntlKeys.kvkCommon]: 'KVK',
  [IntlKeys.ubnCommon]: 'UBN',
  [IntlKeys.orgKvkCommon]: 'KVK',
  [IntlKeys.ubns]: 'UBNs',
  [IntlKeys.gln]: 'GLN',
  [IntlKeys.btw]: 'BTW',
  [IntlKeys.certificates]: 'Certificaten',
  [IntlKeys.notes]: 'Notities',
  [IntlKeys.scheme]: 'Schema',
  [IntlKeys.title]: 'Titel',
  [IntlKeys.filter]: 'Filter',
  [IntlKeys.yes]: 'Ja',
  [IntlKeys.no]: 'Nee',
  [IntlKeys.created]: 'Gemaakt',
  [IntlKeys.requested]: 'Aangevraagd',
  [IntlKeys.valid]: 'Geldig',
  [IntlKeys.updated]: 'Bijgewerkt',
  [IntlKeys.approved]: 'Goedgekeurd',
  [IntlKeys.invoice]: 'Factuur',
  [IntlKeys.message]: 'Bericht',
  [IntlKeys.importantMessage]: 'Belangrijke boodschap',
  [IntlKeys.receipt]: 'Bon',
  [IntlKeys.event]: 'Evenement',
  [IntlKeys.delivery]: 'Levering',
  [IntlKeys.export]: 'Exporteren',
  [IntlKeys.startDate]: 'StartDatum',
  [IntlKeys.endDate]: 'Einddatum',
  [IntlKeys.duration]: 'Looptijd',
  [IntlKeys.admin]: 'Admin',
  [IntlKeys.new]: 'Nieuw',
  [IntlKeys.existing]: 'Bestaande',
  [IntlKeys.application]: 'Applicatie',
  [IntlKeys.vionFarming]: 'Vion Farming',
  [IntlKeys.slaughter]: 'Slacht',
  [IntlKeys.messages]: 'Berichten',
  [IntlKeys.info]: 'Info',
  [IntlKeys.managementInfo]: 'Beheer Info',
  [IntlKeys.customKvkName]: 'KVK Custom Name',
  [IntlKeys.newReport]: 'Nieuw rapport',
  [IntlKeys.manure]: 'Mestverwerking Rapporten',
  [IntlKeys.fermentation]: 'Fermentatie Rapporten',
  [IntlKeys.byProducts]: 'Bij-Producten Rapporten',
  [IntlKeys.companyData]: 'Algemeen',
  [IntlKeys.zero]: '0',
  [IntlKeys.one]: '1',
  [IntlKeys.two]: '2',
  [IntlKeys.three]: '3',
  [IntlKeys.four]: '4',
  [IntlKeys.five]: '5',
  [IntlKeys.duplicate]: 'Dupliceren',
  [IntlKeys.saveAndSubmit]: 'Opslaan en verzenden',
  [IntlKeys.saveAsDraft]: 'Tijdelijk opslaan',
  [IntlKeys.revertToDraft]: 'Terug naar concept',
  [IntlKeys.removeRavCode]: 'RAV-code verwijderen',
  [IntlKeys.addRavCode]: 'RAV-code toevoegen',
  [IntlKeys.addConcept]: 'Concept toevoegen',
  [IntlKeys.templates]: 'Templates',
  [IntlKeys.description]: 'Description',
  [IntlKeys.viewEdit]: 'Bekijk/Bewerk',
  [IntlKeys.update]: 'Update',
  [IntlKeys.deleteForever]: 'Voor altijd verwijderen',
  [IntlKeys.newIdMapping]: 'New ID Mapping',
  [IntlKeys.newAlias]: 'New alias',
  [IntlKeys.validDate]: 'Geldige datum',
  [IntlKeys.uploadDate]: 'Upload datum',
  [IntlKeys.uploadedBy]: 'Geupload door',
  [IntlKeys.type]: 'Type',
  [IntlKeys.primeUbn]: 'Prime UBN',
  [IntlKeys.ubnAliases]: 'UBN Aliases',
  [IntlKeys.uploadedAt]: 'Geüpload op',
  [IntlKeys.downloadAliases]: 'Download Aliases',
  [IntlKeys.continue]: 'Continue',
  [IntlKeys.remove]: 'Remove',
  [IntlKeys.keep]: 'Keep',
  [IntlKeys.createNew]: 'Create New',
  [IntlKeys.or]: 'Or',
  [IntlKeys.labelName]: 'Label name',
  [IntlKeys.assignedTo]: 'Assigned to',
  [IntlKeys.create]: 'Create',
  [IntlKeys.vri]: 'VRI',
  [IntlKeys.epg]: 'EPG',
  [IntlKeys.healthBalance]: 'Health Balance',
  [IntlKeys.balanceWrtBase]: 'Balance w.r.t. Base',
  [IntlKeys.vriTool]: 'VRI Tool',
  [IntlKeys.vs]: 'vs',
  [IntlKeys.score]: 'score',
  [IntlKeys.selectApps]: 'Selecteer Apps',
  [IntlKeys.requestDate]: 'Request Date',
  [IntlKeys.concepts]: 'Concepts',
  [IntlKeys.concept]: 'Concept',
  [IntlKeys.companyAvatar]: 'Company Avatar',
  [IntlKeys.isContactPerson]: 'Is Contact Persoon',
  [IntlKeys.contentAdmin]: 'Content Admin',
  [IntlKeys.userAdmin]: 'User Admin',
  [IntlKeys.monitoringAdmin]: 'Monitoring Admin',
  [IntlKeys.simpleUser]: 'Simple User',
  [IntlKeys.fiveAndMore]: '5 of meer',
  [IntlKeys.lastUpdate]: 'laatste update',
  [IntlKeys.quarter]: 'Kwartaal',
  [IntlKeys.halfYear]: 'Halve-Jaar',
  [IntlKeys.year]: 'Jaar',
  [IntlKeys.timeRange]: 'Tijdsbestek',
  [IntlKeys.lastUpdated]: 'Laatst Bijgewerkt',
  [IntlKeys.colorTheme]: 'Color theme',
  [IntlKeys.quarters]: 'Kwartalen',
  [IntlKeys.halfYears]: 'Halve-Jaren',
  [IntlKeys.years]: 'Jaren',
  [IntlKeys.here]: 'hier',
  [IntlKeys.view]: 'Bekijk',
  [IntlKeys.companyEmail]: 'Bedrijfs Mail',
  [IntlKeys.statusSubmitted]: 'Ingediend',
  [IntlKeys.statusDraft]: 'Bekijk',
  [IntlKeys.submitted]: 'Ingediend',
  [IntlKeys.draft]: 'Bekijk',
  [IntlKeys.isRemovedSuccessfully]: '{item} is succesvol verwijderd',
  [IntlKeys.isCreatedSuccessfully]: '{item} succesvol aangemaakt',
  [IntlKeys.organization]: 'Organisatie',
  [IntlKeys.user]: 'Gebruiker',
  [IntlKeys.adminUserPassport]: 'Gebruikerspaspoort',
  [IntlKeys.alias]: 'Alias',
  [IntlKeys.permissionProfileTemplate]: 'Toestemmingsprofielsjabloon',
  [IntlKeys.samePassword]: 'Het nieuwe wachtwoord moet anders zijn dan het huidige wachtwoord',
  [IntlKeys.passportStatus]: 'Paspoortstatus',

  // titles
  [TitlesKeys.solutionBySIM]: 'een oplossing van IB',
  [TitlesKeys.signIn]: 'Log In',
  [TitlesKeys.signUp]: 'Inschrijven',
  [TitlesKeys.signOut]: 'Afmelden',
  [TitlesKeys.password]: 'Wachtwoord',
  [TitlesKeys.dashboard]: 'Dashboard',
  [TitlesKeys.map]: 'Kaart',
  [TitlesKeys.certificates]: 'Certificaten',
  [TitlesKeys.certificatesDaily]: 'Dagelijks',
  [TitlesKeys.certificatesWeekly]: 'Wekelijks',
  [TitlesKeys.certificatesDeviations]: 'Afwijkingen',
  [TitlesKeys.certificatesFarms]: 'Bedrijf',
  [TitlesKeys.certificatesRecognitions]: 'Erkenning',
  [IntlKeys.titleCertificatesIKB]: 'IKB',
  [IntlKeys.titleCertificatesWelfare]: 'Welfare',
  [IntlKeys.titleCertificatesKiwa]: 'Kiwa',
  [IntlKeys.titleCertificatesKiwaDaily]: 'Kiwa Dagelijks',
  [IntlKeys.titleCertificatesKiwaWeekly]: 'Kiwa Wekelijks',
  [IntlKeys.titleInvoices]: 'Facturen',
  [TitlesKeys.invoiceDetails]: 'Factuur Details',
  [IntlKeys.titleInvoiceMessages]: 'Invoice Messages',
  [TitlesKeys.slaughter]: 'Slacht',
  [IntlKeys.titleSlaughterMessages]: 'Slachtberichten',
  [IntlKeys.titleSlaughterDetails]: 'Slacht details',
  [IntlKeys.titleGenetics]: 'Genetica',
  [IntlKeys.titleDeliveryMessages]: 'Leveringsberichten',
  [IntlKeys.titleDeliverySegment]: 'Delivery Segment',
  [TitlesKeys.deliverySegmentContents]: 'Delivery Segment Contents',
  [IntlKeys.titleUserPassports]: 'Bedrijfs Paspoort',
  [IntlKeys.titleMovementEvents]: 'Verplaatsingsberichten',
  [IntlKeys.titleVeterinarianData]: 'Dierenarts Data',
  [IntlKeys.titleUploadDocument]: 'Bestanden Uploaden',
  [IntlKeys.titleFeedProvenanceValidityTool]: 'Tool Voor Validiteit Van Het Herkomstbestand',
  [IntlKeys.titleAdminFileUpload]: 'Upload Geschiedenis',
  [IntlKeys.titleDataCatalogue]: 'Data Catalogus',
  [IntlKeys.titleVri]: 'Good Farming Star Benchmark',
  [IntlKeys.titleVriCalculated]: 'VRI Tool',
  [TitlesKeys.vriEpg]: 'EPG',
  [TitlesKeys.vriHealthBalance]: 'Gezondheidssaldo',
  [TitlesKeys.vriBalanceBase]: 'Saldo t.o.v. Basis',
  [TitlesKeys.vriReportingTool]: 'VRI Tool', // Vion Rentabiliteits Index Tool
  [IntlKeys.titleVriCalculatedReportingTool]: 'VRI Calculated Tool',
  [TitlesKeys.vriDatabase]: 'Database', // not translatable
  [TitlesKeys.vriDatabaseReports]: 'Database Rapporten',
  [TitlesKeys.myFarm]: 'Bedrijfs Paspoort',
  [IntlKeys.titleMyCompany]: 'Mijn Bedrijf',
  [TitlesKeys.farmStables]: 'Aanmaken Stal Profiel',
  [TitlesKeys.farmReports]: 'Rapporten',
  [IntlKeys.titleFarmUpdates]: 'Updates Log',
  [IntlKeys.titleMyJoinData]: 'Mijn JoinData',
  [TitlesKeys.history]: 'Geschiedenis',
  [TitlesKeys.admin]: 'Admin Paneel',
  [IntlKeys.titleAllOrganizations]: 'Bedrijfs Paspoort',
  [IntlKeys.titleAllUsers]: 'Alle gebruikers',
  [IntlKeys.titleUsers]: 'Gebruikers',
  [IntlKeys.titleCreateOrganization]: 'Organisatie Aanmaken',
  [IntlKeys.titleUploads]: 'Uploads', // not translatable
  [IntlKeys.titleUploadOrgsPassportsUsers]: 'Upload organisatie, paspoorten en gebruikers',
  [IntlKeys.titleUploadOrganization]: 'Upload Organisatie',
  [IntlKeys.titleUploadUserPassports]: 'Upload paspoorten',
  [IntlKeys.titleUploadUsers]: 'Upload Gebruikers',
  [IntlKeys.titleAllRoles]: 'Alle Rollen',
  [IntlKeys.titleAdminMenu]: 'Beheerdersmenu',
  [IntlKeys.titleAnimalPassport]: 'Dierenpaspoort',
  [IntlKeys.titleFarmerInput]: 'Invoerportaal',
  [IntlKeys.titleReportList]: 'Rapportlijst',
  [IntlKeys.titleCompanyReportNew]: 'Nieuw Algemeen Rapporten',
  [IntlKeys.titleCompanyReportEdit]: 'Algemeen Rapporten Bekijken/Bewerken',
  [IntlKeys.titleCompanyReportView]: 'Algemeen Rapporten Bekijken',
  [IntlKeys.titleByProductsNew]: 'Nieuw Bij-Producten Rapporten',
  [IntlKeys.titleByProductsEdit]: 'Bij-Producten Rapporten Bekijken/Bewerken',
  [IntlKeys.titleByProductsView]: 'Bij-Producten Rapporten Bekijken',
  [IntlKeys.titleFermentationNew]: 'Nieuw Fermentatie Rapporten',
  [IntlKeys.titleFermentationEdit]: 'Fermentatie Rapporten Uitzicht/Bewerken',
  [IntlKeys.titleFermentationView]: 'Fermentatie Rapporten Uitzicht',
  [IntlKeys.titleManureNew]: 'Nieuw Mestverwerking Rapporten',
  [IntlKeys.titleManureEdit]: 'Mestverwerking Rapporten Uitzicht/Bewerken',
  [IntlKeys.titleManureView]: 'Mestverwerking Rapporten bedrijf Uitzicht',
  [IntlKeys.titleManageUploads]: 'Manage Uploads',
  [IntlKeys.titleReferenceTables]: 'Reference Tables',
  [IntlKeys.titleUploadsList]: 'Uploads list',
  [IntlKeys.titleManageUploadEdit]: 'Manage Upload View/Edit',
  [IntlKeys.titleFarmManagementData]: 'Farm Management Data',
  [IntlKeys.titleFarmManagement]: 'Farm Management',
  [IntlKeys.titleGroupFinancialEvents]: 'Group - Financial Events',
  [IntlKeys.titleGroupGroups]: 'Group - Groups',
  [IntlKeys.titleFtpUpdates]: 'FTP Updates',
  [IntlKeys.titleGroupWeights]: 'Group - Group Weight',
  [IntlKeys.titleGroupRemovals]: 'Group - Group Removal',
  [IntlKeys.titleGroupsArrival]: 'Group - Groups Arrival',
  [IntlKeys.titleGroupMoves]: 'Group - Group Move',
  [IntlKeys.titleCodesAnimalRemarks]: 'Codes - Animal Remark',
  [IntlKeys.titleCodesDefects]: 'Codes - Defect',
  [IntlKeys.titleCodesDiagnose]: 'Codes - Diagnose',
  [IntlKeys.titleCodesAnimalScore]: 'Codes - Animal Score',
  [IntlKeys.titleCodesInsemination]: 'Codes - Insemination',
  [IntlKeys.titleCodesTreatments]: 'Codes - Treatment',
  [IntlKeys.titleGroupPurchasedFood]: 'Group - Food',
  [IntlKeys.titleGroupUsedFood]: 'Group - Food Used',
  [IntlKeys.titleCodesMatingType]: 'Codes - Mating Type',
  [IntlKeys.titleCodesWeightType]: 'Codes - Weight Type',
  [IntlKeys.titleCodesRemovalType]: 'Codes - Removal Type',
  [IntlKeys.titleCodesAnimalIdentityType]: 'Codes - Animal Identity Type',
  [IntlKeys.titleCodesFarmOption]: 'Codes - Farm Options',
  [IntlKeys.titleCodesBarn]: 'Codes - Barn',
  [IntlKeys.titleCodesRoom]: 'Codes - Room',
  [IntlKeys.titleCodesPen]: 'Codes - Pen',
  [IntlKeys.titleFms]: 'FMS',
  [IntlKeys.titleFarms]: 'Farms',
  [IntlKeys.titleStablesGroups]: 'Stables Groups',
  [IntlKeys.titleValidityCertificates]: 'Validity Certificates',
  [IntlKeys.titleReports]: 'Reports',
  [IntlKeys.titleAliases]: 'Aliases',
  [IntlKeys.titleCarbonFootprints]: 'CO2-voetafdruk',
  [IntlKeys.titleFarmsList]: 'Farms List',
  [IntlKeys.titleUploadAliases]: 'Upload Aliases',
  [IntlKeys.titleMonitoringDashboard]: 'Monitoring Dashboard',
  [IntlKeys.titleSuppliers]: 'Suppliers',
  [IntlKeys.titleData]: 'Gegevens',
  [IntlKeys.titleLabels]: 'Labels',
  [IntlKeys.titleManageLabels]: 'Manage labels',
  [IntlKeys.titleCreateLabel]: 'Create label',
  [IntlKeys.titleVriComparisonTool]: 'VRI Comparison Tool',
  [IntlKeys.titleVriTotal]: 'VRI',
  [IntlKeys.titleOrganization]: 'Organisaties',
  [IntlKeys.titleParticipation]: 'Machtigingen',
  [IntlKeys.titleVisits]: 'Weergaven',
  [IntlKeys.titleCodesAccount]: 'Codes - Account',
  [IntlKeys.titleCodesEventTypes]: 'Codes - Event Type',
  [IntlKeys.titleGroupExpense]: 'Group - Expense',
  [IntlKeys.titleRawMaterialsOrigin]: 'CFP Herkomstgrondstoffen',
  [IntlKeys.titleCarbonFootprintDetails]: 'Carbon Footprint Details',
  [IntlKeys.titleHFMOutput]: 'CFP Scope 1&2',
  [IntlKeys.titleVionGoodFarmingStar]: 'Vion Good Farming Star',
  [IntlKeys.titleVionGoodFarmingBalance]: 'Vion Good Farming Balance',
  [IntlKeys.titleOther]: 'Other',
  [IntlKeys.titleErrorsLog]: 'Errors Log',
  [IntlKeys.titleBreeding]: 'Vermeerdering',
  [IntlKeys.titleFlattening]: 'Vleesvarkens',
  [IntlKeys.titleClosed]: 'Gesloten',

  // UI

  [UiKeys.bulkAction]: 'Bulkactie',
  [UiKeys.showMenu]: 'Menu weergeven',
  [UiKeys.displayAs]: 'Weergeven als',
  [UiKeys.list]: 'Lijst',
  [UiKeys.cards]: 'Kaarten',
  [UiKeys.filterItems]: 'Items Filteren',
  [UiKeys.all]: 'Alle',
  [UiKeys.today]: 'Vandaag',
  [UiKeys.thisWeek]: 'Laatste 7 dagen',
  [UiKeys.thisMonth]: 'Deze maand',
  [UiKeys.dateRange]: 'Datumbereik',
  [UiKeys.from]: 'van',
  [UiKeys.to]: 'naar',
  [UiKeys.resetFilters]: 'Reset Filters', // not translatable
  [UiKeys.applyFilters]: 'Filters Toepassen',
  [UiKeys.tableSettings]: 'Tabelinstellingen',
  [UiKeys.applySettings]: 'Instellingen toepassen',
  [UiKeys.resetToDefault]: 'Terug zetten naar standaard',
  [IntlKeys.details]: 'Details',
  [IntlKeys.sftpAccount]: 'SFTP account',
  [IntlKeys.fileName]: 'Bestandsnaam',
  [UiKeys.proceed]: 'Doorgaan',
  [UiKeys.discard]: 'Negeren',
  //
  [IntlKeys.uiEdit]: 'Bewerken',
  [IntlKeys.uiResetPassword]: 'Reset Wachtwoord',
  [IntlKeys.uiSaveAndClose]: 'Save and close',
  [IntlKeys.uiCloseWOSaving]: 'Close Without Saving',
  [IntlKeys.uiBack]: 'Terug',
  [IntlKeys.uiCreated]: 'Aangemaakt',
  [IntlKeys.uiUpdated]: 'Bijgewerkt',
  [IntlKeys.uiValid]: 'Geldig',
  [IntlKeys.uiNoOptions]: 'Geen opties',
  [IntlKeys.uiNothingSelected]: 'Niets geselecteerd',
  [IntlKeys.uiNothingFound]: 'Nothing found',
  [IntlKeys.uiOpenPassport]: 'Open Passport',
  [IntlKeys.sync]: 'Sync',
  [IntlKeys.validate]: 'Valideren',
  [IntlKeys.process]: 'Verwerken',

  // admin

  [IntlKeys.adminUsersName]: 'Naam',
  [IntlKeys.adminUsersFullName]: 'Voor-en achternaam',
  [IntlKeys.adminUsersConcepts]: 'Concepts',
  [IntlKeys.adminUsersCompanyId]: 'Bedrijfs ID',
  [IntlKeys.adminUsersOrganizations]: 'Organisaties',
  [IntlKeys.adminUsersOrganizationIds]: "Organisatie-ID's",
  [IntlKeys.adminUsersUserPassportIds]: "Gebruikerspaspoort-ID's",
  [IntlKeys.adminUsersCompanies]: "Bedrijven",
  [IntlKeys.adminUsersOrganizationName]: 'Organisatie Naam',
  [IntlKeys.adminUsersPrefLang]: 'Voorkeurstaal',
  [IntlKeys.adminUsersUid]: 'UID',
  [IntlKeys.adminUsersProvider]: 'Leverancier',
  [IntlKeys.adminUsersUserRole]: 'Gebruikersrol',
  [IntlKeys.adminUsersUserTypeDescriptions]: 'Beschrijvingen gebruikersrollen',
  [IntlKeys.adminUsersUserType]: 'Gebruikerstype',
  [IntlKeys.adminUsersUserPermissionProfile]: 'Toestemming Profiel',
  [IntlKeys.adminUsersUserPermissionProfileTitle]: 'titel',
  [IntlKeys.adminUsersIsContactPerson]: 'Is Contactpersoon',
  [IntlKeys.adminUsersStatus]: 'Toestand',
  [IntlKeys.adminUsersSuspend]: 'Suspend',
  [IntlKeys.adminUsersActivate]: 'Activeren',
  [IntlKeys.adminUsersDeleteUser]: 'Verwijder gebruiker',
  [IntlKeys.adminUsersSaveAndNotify]: 'Stuur En op de hoogte via e-mail',
  [IntlKeys.adminUsersSaveChanges]: 'Wijzigingen Opslaan',
  [IntlKeys.adminUsersCloseWOSaving]: 'Sluiten zonder opslaan',
  [IntlKeys.adminUsersCreateNewUser]: 'Maak een nieuwe Gebruiker aan',
  [IntlKeys.adminUsersCreateNewUserPassport]: 'Nieuwe gebruiker Passport',
  [IntlKeys.adminUsersSimpleUser]: 'eenvoudige gebruikersinterface',
  [IntlKeys.adminUsersContentAdmin]: 'content admin',
  [IntlKeys.adminUsersUserAdmin]: 'gebruiker admin',
  [IntlKeys.adminUsersNewUserPassport]: 'NIEUWE GEBRUIKER PASSPORT',
  [IntlKeys.adminUsersSuspended]: 'niet actief',
  [IntlKeys.adminUsersActive]: 'actief',
  [IntlKeys.adminUsersInvited]: 'uitgenodigd',
  [IntlKeys.adminUsersFarmer]: 'varkenshouder',
  [IntlKeys.adminUsersTransporter]: 'transporter',
  [IntlKeys.adminUsersVeterinarian]: 'dierenarts',
  [IntlKeys.adminUsersLastSignInAt]: 'Laatste aanmelding op',
  [IntlKeys.adminUsersSignInCount]: 'Aantal logins',
  [IntlKeys.userProfileId]: 'Gebruiker Profiel Id',
  [IntlKeys.userFullName]: 'Gebruikersnaam',
  [IntlKeys.userEmail]: 'Gebruikersmail',
  [IntlKeys.doYouWantToSubmitPassport]: 'Wilt u uw paspoort indienen ter controle en goedkeuring? Houd er rekening mee dat uw paspoort na het indienen niet meer bewerkt kan worden',

  // permissions

  [IntlKeys.adminPermissionProfileTemplate]: 'Toestemmingsprofielsjabloon',
  [IntlKeys.adminPermissionRules]: 'Toestemmingsregels',
  [IntlKeys.permissionAccessTo]: 'Toegang tot',

  [IntlKeys.adminPermissionsGroupDataset]: 'Dataset',
  [IntlKeys.adminPermissionsGroupApplications]: 'Toepassingen',
  [IntlKeys.adminPermissionsAlwaysOn]: 'Altijd aan',
  [IntlKeys.adminPermissionsAlwaysOff]: 'Altijd uit',
  [IntlKeys.adminPermissionsOrganizationTypePlaceholder]: 'Organisatie Type Of Zichtbaarheid Naam',
  [IntlKeys.adminPermissionsNotesPlaceholder]: 'Opmerkingen (bijv. Beschrijving van het type organisatie)',

  [IntlKeys.adminPermissionsSourceNone]: '-',
  [IntlKeys.adminPermissionsSourceFtp]: 'FTP',
  [IntlKeys.adminPermissionsSourceUpload]: 'Handmatig uploaden',
  [IntlKeys.adminPermissionsSourceEdiCircle]: 'EDI Circle (ander systeem)',
  [IntlKeys.adminPermissionsSourceJoindata]: 'Joindata (ander systeem)',
  [IntlKeys.adminPermissionsSourceOrganizations]: 'Systeem',

  [IntlKeys.adminPermissionsStatusAlwaysOn]: 'Altijd aan',
  [IntlKeys.adminPermissionsStatusAlwaysOff]: 'Altijd uit',
  [IntlKeys.adminPermissionsStatusAllowedFrom]: 'Toestaan vanaf {from}',
  [IntlKeys.adminPermissionsStatusAllowedTo]: 'Toestaan tot {to}',
  [IntlKeys.adminPermissionsStatusAllowedFromTo]: 'Toestaan van {from} tot {to}',

  [IntlKeys.adminCreatePermissionProfileTemplate]: 'Maak Toestemming profielsjabloon',
  [IntlKeys.adminEditPermissionProfileTemplate]: 'Machtigingen bewerken profielsjabloon',

  [IntlKeys.adminOrganizationsRegistrationName]: 'Registratie Naam',
  [IntlKeys.adminOrganizationsStreet]: 'Straat',
  [IntlKeys.adminOrganizationsEdiCircleCode]: 'Edi Circle Code',
  [IntlKeys.adminOrganizationsPostcode]: 'Postcode',
  [IntlKeys.adminOrganizationsPlace]: 'Plaats',
  [IntlKeys.adminOrganizationsPhone]: 'Telefoon',
  [IntlKeys.adminOrganizationsCertificationAuthority]: 'Certificeringsinstantie',
  [IntlKeys.adminOrganizationsFeedSuppliers]: 'Voerleveranciers',
  [IntlKeys.adminOrganizationsVisibilityRules]: 'Zichtbaarheidsregels',
  [IntlKeys.adminOrganizationsNewUser]: 'Nieuwe Gebruiker',
  [IntlKeys.adminOrganizationsLastUpdate]: 'Laatste update',
  [IntlKeys.adminOrganizationsBy]: 'Door',
  [IntlKeys.adminOrganizationsOrganizationId]: 'Organization Id',
  [IntlKeys.adminOrganizationsFeedSupplierOther]: 'Voerleverancier (anders)',
  [IntlKeys.adminOrganizationsCertificates]: 'Certificaten',
  [IntlKeys.adminOrganizationsCertificatesOther]: 'Certificaten (anders)',
  [IntlKeys.adminOrganizationsGeneticSuppliers]: 'Genetica Leverancier',
  [IntlKeys.adminOrganizationsGeneticSuppliersOther]: 'Genetica Leverancier (anders)',
  [IntlKeys.adminOrganizationsKiStation]: 'KI Station',
  [IntlKeys.adminOrganizationsKiStationOther]: 'KI Station (anders)',
  [IntlKeys.adminOrganizationsSowLines]: 'Zeugenlijnen',
  [IntlKeys.adminOrganizationsSowLineOther]: 'Zeugenlijn (anders)',
  [IntlKeys.adminOrganizationsBreedingProgram]: 'Rotatie Kruising',
  [IntlKeys.adminOrganizationsSireLines]: 'Berenlijn',
  [IntlKeys.adminOrganizationsSireLineOther]: 'Berenlijn (anders)',
  [IntlKeys.adminOrganizationsVeterinaryPractice]: 'Dierenartspraktijk',
  [IntlKeys.adminOrganizationsVeterinaryPracticeOther]: 'Dierenartspraktijk (anders)',
  [IntlKeys.adminOrganizationsFmsNames]: 'Farm Management Systeem',
  [IntlKeys.adminOrganizationsFmsModuleAgrisysts]: 'FMS Module Agrisyst',
  [IntlKeys.adminOrganizationsFmsModuleAgroCoops]: 'FMS Module Agroscoop',
  [IntlKeys.adminOrganizationsFmsModuleAgrovisions]: 'FMS Module Agrovision',
  [IntlKeys.adminOrganizationsFeedingSystems]: 'Voerinstallatie',
  [IntlKeys.adminOrganizationsFeedingSystemOther]: 'Voerinstallatie (anders)',
  [IntlKeys.adminOrganizationsClimateSystems]: 'Klimaatsysteem',
  [IntlKeys.adminOrganizationsClimateSystemOther]: 'Klimaatsysteem (anders)',

  [IntlKeys.adminOrganizationMyUsers]: 'Gebruikers van Mijn Dashboard',
  [IntlKeys.adminOrganizationOtherUsers]: 'Gebruikers van Mijn Data',
  [IntlKeys.adminOrganizationOtherUsersDescription]: 'Hier ziet u welke ketenpartner toegang heeft tot uw gegevens.',
  [IntlKeys.adminOrganizationAddExistingUsers]: 'Voeg bestaande gebruikers toe',
  [IntlKeys.adminOrganizationGetInTouch]: 'Neem contact met ons op via de mail',
  [IntlKeys.adminOrganizationGetInTouchDescription]: 'Wilt u iets aanpassen?',
  // prettier-ignore
  [IntlKeys.adminOrganizationDataSharingDescription]: 'Hieronder ziet u een overzicht van de applicaties die middels JoinData machtigingen voor u beschikbaar zijn op uw Dashboard. Per applicatie kunt u zien welke gegevens er gebruikt worden en voor wie deze gegevens inzichtelijk zijn. Deze gegevens zijn enkel zichtbaar voor andere ketenpartners wanneer de machtiging status lopend is.',
  [IntlKeys.adminOrganizationDataSharingButton]: 'Details over het delen van mijn data',

  [IntlKeys.adminPermissionApplyToAll]: 'Gelden Voor Iedereen',
  [IntlKeys.adminUsersUserId]: 'gebruikersnaam',
  [IntlKeys.adminRolesRoleId]: 'Rol-ID',
  [IntlKeys.userPassport]: 'Gebruikerspaspoort',
  [IntlKeys.userPassportId]: "Gebruikerspaspoort-ID's",
  [IntlKeys.userPassportUbnCustomName]: 'UBN Custom Name',
  [IntlKeys.userPassportSetUbnCustomName]: 'Set UBN Custom Name',
  [IntlKeys.passportProfileId]: 'Gebruikerspaspoort Profiel Id',

  // animal passport

  [IntlKeys.animalPassportAverageWeightKg]: 'Gemiddeld gewicht (kg)',
  [IntlKeys.animalPassportAnimalCategory]: 'Diercategorie',
  [IntlKeys.animalPassportDetails]: 'Details',
  [IntlKeys.animalPassportCustom]: 'Aangepast',

  [IntlKeys.animalPassportDataTabsFoodSafety]: 'Voedselveiligheid',
  [IntlKeys.animalPassportDataTabsAnimalWelfare]: 'Dierenwelzijn',
  [IntlKeys.animalPassportDataTabsProductIntegrity]: 'Productintegriteit',
  [IntlKeys.animalPassportDataTabsSustainability]: 'Duurzaamheid',

  [IntlKeys.animalPassportDataTabsCertification]: 'Certificatie',
  [IntlKeys.animalPassportDataTabsMedication]: 'Medicatie',
  [IntlKeys.animalPassportDataTabsFeed]: 'Voedsel',
  [IntlKeys.animalPassportDataTabsTransport]: 'Vervoer',
  [IntlKeys.animalPassportDataTabsFarm]: 'Boerderij',
  [IntlKeys.animalPassportDataTabsSlaughter]: 'Slachterij',
  [IntlKeys.animalPassportDataTabsGenetics]: 'Genetica',

  [IntlKeys.animalPassportCertificatesCertificate]: 'Certificaat',
  [IntlKeys.animalPassportCertificatesStatus]: 'Status',
  [IntlKeys.animalPassportCertificatesValidUntil]: 'Geldig tot',

  [IntlKeys.animalPassportFarmDeathCause]: 'Doodsoorzaak',
  [IntlKeys.animalPassportFarmAnimalCount]: 'Aantal Dieren',
  [IntlKeys.animalPassportFarmAverageFeedType]: 'Voer type',
  [IntlKeys.animalPassportFarmAverageAnimalWeight]: 'Geslacht gewicht',

  [IntlKeys.animalPassportFeedGmpType]: 'GMP-type',
  [IntlKeys.animalPassportFeedItemQuantity]: 'Artikelhoeveelheid',

  [IntlKeys.animalPassportGeneticsFemaleType]: 'Vrouwelijk type',
  [IntlKeys.animalPassportGeneticsFemaleAmount]: 'Aantal zeugen',
  [IntlKeys.animalPassportGeneticsMaleType]: 'Mannelijk type',
  [IntlKeys.animalPassportGeneticsMaleAmount]: 'Aantal beren',

  [IntlKeys.animalPassportMedicationAnimalLastExportDate]: 'Laatste exportdatum',
  [IntlKeys.animalPassportMedicationAnimalPreviousExportDate]: 'Vorige exportdatum',
  [IntlKeys.animalPassportMedicationAnimalLastDddTm]: 'Laatste DDD TM',
  [IntlKeys.animalPassportMedicationAnimalPreviousDddTm]: 'Vorige DDD TM',

  [IntlKeys.animalPassportSlaughterAnimalType]: 'Dier type',
  [IntlKeys.animalPassportSlaughterAnimalCount]: 'Aantal dieren',
  [IntlKeys.animalPassportSlaughterCarcassCodeTotal]: 'Karkascode: {carcassCode} totaal',
  [IntlKeys.animalPassportSlaughterOrganCodeTotal]: 'Orgaancode: {organCode} totaal',

  [IntlKeys.animalPassportTransportNumberOfAnimals]: 'Aantal Dieren',

  [IntlKeys.animalTypeGiltOrCastratedBoar]: 'Geclassificeerd varken (Gelt of Borg)',
  [IntlKeys.animalTypeBoar]: 'Volle Beer',

  [IntlKeys.animalCategorySowsAndBreedingGilts]: 'Fokkerij Zeugen en Gelten',
  [IntlKeys.animalCategoryBreedingBoars]: 'Fokkerij Beren',
  [IntlKeys.animalCategoryPiglets]: 'Biggen',
  [IntlKeys.animalCategorySlaughterPigs]: 'Vleesvarkens',
  [IntlKeys.animalCategorySlaughterSowsAndPiglets]: 'Slachtzeugen en Slachtbiggen',

  [IntlKeys.carcassCodePleurisy]: 'Pleuritis',
  [IntlKeys.carcassCodeInflamedSkin]: 'Ontstoken Huid',
  [IntlKeys.carcassCodeInflamedLeg]: 'Ontstoken Poot',

  [IntlKeys.organCodeLiverAffected]: 'Lever Aangetast',
  [IntlKeys.organCodeLiverDisapproved]: 'Lever Afgekeurd',
  [IntlKeys.organCodeLungDisapproved]: 'Long Afgekeurd',

  [IntlKeys.dataNotToRate]: 'Niet te Beoordelen',
  [IntlKeys.dataNotClassified]: 'Niet geclassificeerd',

  // dashboard

  [IntlKeys.dashboardFakeCategory]: 'FAKE_CATEGORY',
  [IntlKeys.dashboardApplications]: 'Mijn Applicaties',
  [IntlKeys.dashboardDataset]: 'Mijn Gegevens',
  [IntlKeys.dashboardVriHealthBalance]: 'Gezondheids-saldo',
  [IntlKeys.dashboardVriBalanceBase]: 'Saldo t.o.v. Basis',
  [IntlKeys.dashboardDeliveryMessages]: 'Leverings-Berichten',
  [IntlKeys.dashboardMovementEvents]: 'Verplaatsings-Berichten',

  [IntlKeys.dashboardTooltipAllowed]: 'Toegestaan',
  [IntlKeys.dashboardTooltipFrom]: 'van',
  [IntlKeys.dashboardTooltipTo]: 'naar',
  [IntlKeys.dashboardTooltipDataSources]: 'Deze applicatie gebruikt gegevens uit de volgende data bronnen:',
  [IntlKeys.dashboardTooltipDetailedDataUsageInfo]: 'Gedetailleerde informatie over datagebruik',

  [IntlKeys.userPassportDraftAlert]: 'Organisatie paspoort is nog niet verzonden, navigeer naar \'Mijn bedrijf\' om uw organisatie paspoort compleet te maken en te verzenden',
  [IntlKeys.userPassportSubmittedAlert]: 'Organisatie paspoort wordt gecontroleerd, neem contact op met powerchain@impactbuying.com als u vragen heeft',
  [IntlKeys.userPassportRejectedAlert]: 'De status van de organisatie paspoort is "afgewezen", raadpleeg uw e-mail voor meer informatie of neem contact met ons op via powerchain@impactbuying.com',

  // uploads

  [UploadsKeys.uploadFile]: 'Upload bestand',
  [UploadsKeys.dragNDrop]: 'Drag & Drop',
  [UploadsKeys.dndToUpload]: 'Uploaden',
  [UploadsKeys.dndOr]: 'Of',
  [UploadsKeys.dndSelectFile]: 'Selecteer bestand',
  [UploadsKeys.dndSupportedFormats]: 'Ondersteunde formaten: .xls .xlsx',
  [UploadsKeys.dndSingleFile]: 'Sleep alstublieft een enkel bestand',
  [IntlKeys.uploadsDownloadTemplate]: 'Download Sjabloon',
  [IntlKeys.uploadsDndSupportedFormatsCsv]: 'Ondersteunde formaten: .csv',
  [IntlKeys.discardFile]: 'Bestand verwijderen',
  [IntlKeys.yourFile]: 'Uw bestand',

  [UploadsKeys.uploadDaily]: 'Upload Dagelijks',
  [UploadsKeys.uploadWeekly]: 'Upload Wekelijks',
  [UploadsKeys.uploadVriDatabase]: 'Upload VRI Database',
  [UploadsKeys.uploadHistory]: 'Upload Geschiedenis',

  [UploadsKeys.pleaseStandBy]: 'Please Stand by',
  [UploadsKeys.calculating]: 'berekenen...',
  [UploadsKeys.nSeconds]: '{n} seconden',
  [UploadsKeys.uploadSuccessful]: 'Succesvol Geupload',
  [UploadsKeys.alreadyUploaded]: 'Dit bestand bestaat al',
  [UploadsKeys.alreadyUploadedMsgConfirm]: 'Dit bestand lijkt al geüpload, toch doorgaan?',

  [UploadsKeys.fileName]: 'Bestandsnaam',
  [UploadsKeys.fileSize]: 'Bestandsgrootte',
  [UploadsKeys.fileType]: 'Bestandstype',
  [UploadsKeys.processingTime]: 'Verwerkingstijd',

  [UploadsKeys.nSelected]: '{n} geselecteerd',
  [UploadsKeys.sortedAscending]: 'Oplopende sortering',
  [UploadsKeys.sortedDescending]: 'Aflopende sortering',
  [UploadsKeys.densePadding]: 'Dichte vulling',
  [UploadsKeys.filterList]: 'Filterlijst',

  [IntlKeys.uploadsUploadReferenceTables]: 'Upload Reference Tables',

  [IntlKeys.uploadsUploadCarbonFootprint]: 'Upload Carbon Footprint',

  [IntlKeys.uploadsUploadHFMOutput]: 'Upload HFM Output',

  // certificates

  [IntlKeys.certificatesBlk]: 'Beter Leven Keurmerk 1 ster',
  [IntlKeys.certificatesPigsTomorrow]: 'Varken van Morgen',
  [IntlKeys.certificatesIkb]: 'IKB Nederland Varkens',
  [IntlKeys.certificatesVionWelfare]: 'Vion Welfare',
  [IntlKeys.certificatesActive]: 'Actief',
  [IntlKeys.certificatesAboutToExpire]: 'Bijna verlopen',
  [IntlKeys.certificatesExpired]: 'Verlopen',

  // certificates IKB

  [IntlKeys.certificateIKBUbn]: 'UBN',
  [IntlKeys.certificateIKBValidFrom]: 'Geldig vanaf',
  [IntlKeys.certificateIKBValidTo]: 'Geldig tot',
  [IntlKeys.certificateIKBType]: 'Type',

  // certificates kiwa daily

  [CertificatesKiwaDailyKeys.RegNumber]: 'Reg Nummer',
  [CertificatesKiwaDailyKeys.CountryCodeLoc]: 'Landcode Loc.',
  [CertificatesKiwaDailyKeys.BusinessType]: 'bedrijfstype',
  [CertificatesKiwaDailyKeys.OwnTransport]: 'EigenVervoer',
  [CertificatesKiwaDailyKeys.ValidUntil]: 'Geldig tot',
  [CertificatesKiwaDailyKeys.Remark]: 'Opmerking',
  [CertificatesKiwaDailyKeys.VC]: 'VC',
  [CertificatesKiwaDailyKeys.QSNumber]: 'QS nummer',
  [CertificatesKiwaDailyKeys.GGNr]: 'GG Nr.',
  [CertificatesKiwaDailyKeys.PigTomorrow]: 'Varken van Morgen',
  [CertificatesKiwaDailyKeys.BLK]: 'BLK',
  [CertificatesKiwaDailyKeys.CertificateComment]: 'Certificaat opmerking',
  [CertificatesKiwaDailyKeys.SalmonellaInfo]: 'Salmonella info',

  //certificates kiwa weekly farms

  [CertificatesKiwaWeeklyFarms.UBN]: 'UBN',
  [CertificatesKiwaWeeklyFarms.Name]: 'Naam',
  [CertificatesKiwaWeeklyFarms.Address]: 'Adres',
  [CertificatesKiwaWeeklyFarms.PostalCode]: 'Postcode',
  [CertificatesKiwaWeeklyFarms.Place]: 'Plaats',
  [CertificatesKiwaWeeklyFarms.Phone]: 'Telefoon',
  [CertificatesKiwaWeeklyFarms.Date]: 'Datum',
  [CertificatesKiwaWeeklyFarms.Sows]: 'Zeugen',
  [CertificatesKiwaWeeklyFarms.Vlv]: 'Vleesvarkens (vlv)',
  [CertificatesKiwaWeeklyFarms.Program]: 'Programma',
  [CertificatesKiwaWeeklyFarms.Remark]: 'Opmerking',
  [CertificatesKiwaWeeklyFarms.Type]: 'Type',

  // certificates kiwa weekly recognitions

  [CertificatesKiwaWeeklyRecognitions.UBN]: 'UBN',
  [CertificatesKiwaWeeklyRecognitions.Date]: 'Datum',
  [CertificatesKiwaWeeklyRecognitions.Program]: 'Programma',
  [CertificatesKiwaWeeklyRecognitions.Code]: 'Code',
  [CertificatesKiwaWeeklyRecognitions.Regnr]: 'Regnr',
  [CertificatesKiwaWeeklyRecognitions.Name]: 'Naam',

  // certificates kiwa weekly deviations

  [CertificatesKiwaWeeklyDeviations.UBN]: 'UBN',
  [CertificatesKiwaWeeklyDeviations.Program]: 'Programma',
  [CertificatesKiwaWeeklyDeviations.Date]: 'Datum',
  [CertificatesKiwaWeeklyDeviations.QuestionCode]: 'VraagCode',
  [CertificatesKiwaWeeklyDeviations.Remark]: 'Opmerking',
  [CertificatesKiwaWeeklyDeviations.Answer]: 'Antwoord',

  // certificates welfare

  [CertificatesWelfareKeys.ubn]: 'UBN',
  [CertificatesWelfareKeys.validFrom]: 'Geldig vanaf',
  [CertificatesWelfareKeys.validTo]: 'Geldig tot',
  [CertificatesWelfareKeys.type]: 'Type',

  // VRI

  [VriKeys.vriRanking]: 'VRI Ranking',
  [VriKeys.goToVriReportingTool]: 'Ga naar VRI reporting tool',
  [VriKeys.vriTotalRanking]: 'VRI Totaal Ranking',
  [VriKeys.serialNumber]: 'Volgnummer',
  [VriKeys.yourScoreLabel]: 'uw score',
  [VriKeys.centsPerKilogram]: 'ct per kg',
  [VriKeys.perPig]: 'per varken',
  [VriKeys.vriEpgTitle]: 'VRI EPG',
  [VriKeys.vriHealthBalanceTitle]: 'VRI Gezondheidssaldo',
  [VriKeys.vriBalanceBaseTitle]: 'VRI Saldo t.o.v. Basis',
  [VriKeys.chartUbn]: 'UBN',
  [VriKeys.chartName]: 'Naam',
  [VriKeys.chartTotal]: 'Totaal',
  [VriKeys.chartEpg]: 'EPG',
  [VriKeys.chartClassification]: 'Classificatie',
  [VriKeys.chartHealth]: 'Gezondheid',
  [VriKeys.top20Label]: 'top 20%',
  [VriKeys.avgLabel]: 'gem',
  [VriKeys.averageLabel]: 'gemiddeld',
  [VriKeys.pigsCapacity]: 'Aantal Vleesvarkens Plaatsen',
  [VriKeys.differenceLabel]: 'verschil',
  [VriKeys.totalCompany]: 'Total Bedijf',
  [VriKeys.database]: 'Database',
  [VriKeys.databaseDetails]: 'Database',
  [VriKeys.uploadedFile]: 'Geüploade bestanden',
  [IntlKeys.datapoint]: 'Datapoint',

  // VRI Balance Base

  [VriKeys.balanceBaseAverageWeightCorrection]: 'Gem Gewicht / Correctie (ct/kg)',
  [VriKeys.balanceBaseAverageFatCorrection]: 'Gem spek / correctie (ct/kg)',
  [VriKeys.balanceBaseAverageMuscleCorrection]: 'Gem spier / correctie (ct/kg)',
  [VriKeys.balanceBaseAverageMeatCorrection]: 'Gem vlees% / correctie (ct/kg)',

  // VRI values

  [VriKeys.id]: 'Id',
  [VriKeys.rating]: 'Concept',
  [VriKeys.vriSerialNumber]: 'VRI serienummer',
  [VriKeys.ubn]: 'UBN',
  [VriKeys.name]: 'NAAM',
  [VriKeys.total]: 'VRI TOTAAL',
  [VriKeys.vriEpg]: 'VRI EPG',
  [VriKeys.epgSerialNumber]: 'Volgnummer',
  [VriKeys.growth]: 'Groei (gr/dag)',
  [VriKeys.feedIntakePerDay]: 'VC',
  [VriKeys.ewConversion]: 'EW-Conversie',
  [VriKeys.layingBigWeight]: 'Opleg Gewicht Big (kg)',
  [VriKeys.loss]: 'Uitval (%)',
  [VriKeys.epg]: 'EPG (€)',
  [VriKeys.vriClassification]: 'VRI Classificatie',
  [VriKeys.epgOrderNumber]: 'epg Volg-nummer',
  [VriKeys.balanceWithRespectToBase]: 'Saldo t.o.v. Basis (ct/kg)',
  [VriKeys.averageWeight]: 'Gem geslachtgewicht (kg)',
  [VriKeys.averageWeightCorrection]: 'Gem gewicht correctie (ct/kg)',
  [VriKeys.averageFatThickness]: 'Gem. spekdikte (mm)',
  [VriKeys.averageBeaconCorrection]: 'Gem spek correctie (ct/kg)',
  [VriKeys.averageMuscleThickness]: 'Gem spierdikte (mm)',
  [VriKeys.averageMuscleCorrection]: 'Gem spier correctie (ct/kg)',
  [VriKeys.averageMeat]: 'Gem vlees (%)',
  [VriKeys.averageMeatCorrection]: 'Gem vlees% correctie (ct/kg)',
  [VriKeys.averageClassificationCorrection]: 'Gem classificatie correctie (ct/kg)',
  [VriKeys.averageSexAllowance]: 'Gem sexe toeslag (ct/kg)',
  [VriKeys.averageConceptSurcharge]: 'Gem concept toeslag (ct/kg)',
  [VriKeys.pigsInDraft]: 'Varkens in concept (%)',
  [VriKeys.vriHealth]: 'VRI gezondheid',
  [VriKeys.epgNumber]: 'Epg Volgnummer',
  [VriKeys.euroPerPig]: 'Gezondheidssaldo (€ per varken)',
  [VriKeys.approvedCarcass]: 'Karkas Goed gekeurd',
  [VriKeys.pleurisy]: 'Pleuritis',
  [VriKeys.inflamedSkin]: 'Ontst. huid',
  [VriKeys.inflamedLeg]: 'Ontst. poot',
  [VriKeys.approvedOrgan]: 'Orgaan Goed gekeurd',
  [VriKeys.leverAfg]: 'Lever afg.',
  [VriKeys.liverAfg]: 'Lever afg.',
  [VriKeys.longAfg]: 'Long afg.',
  [VriKeys.lungAfg]: 'Long afg.',
  [VriKeys.healthy]: 'Geen Karkas en Orgaan afwijkingen (%)',
  [VriKeys.vriTotal]: 'VRI Totaal',
  [VriKeys.lapSpeed]: 'Rondesnelheid',
  [VriKeys.deliveredAnimals]: 'Afgeleverde dieren',
  [VriKeys.delivered]: 'Afgeleverde kg',
  [VriKeys.vriBalanceWithRespectToBase]: 'Balance with respect to VRI Base', // NOT TRANSLATED ??
  [VriKeys.endManufacturer]: 'Eindbeer',
  [VriKeys.feedingSystem]: 'Voer System',
  [VriKeys.reportDateTo]: 'Datum rapporteren aan',
  [VriKeys.reportDateFrom]: 'Rapportdatum vanaf',
  [VriKeys.euroPerPigMax]: 'Euro per Pig Max',
  [VriKeys.euroPerPigMin]: 'Euro per Pig Min',
  [VriKeys.epgMax]: 'EPG Max',
  [VriKeys.epgMin]: 'EPG Min',
  [VriKeys.averageEpgWeight]: 'Levend gewicht (kg)',
  [VriKeys.averageBirthWeight]: 'Raising weight piglets',
  [IntlKeys.vriFeedIntakePerDay]: 'VC',
  [IntlKeys.vriAverageNutrientEw]: 'EW-Conversie',
  [IntlKeys.vriRemovedPigsCount]: 'Uitval (%)',
  [IntlKeys.vriAverageEpgWeight]: 'Levend gewicht (kg)',
  [IntlKeys.vriAverageBirthWeight]: 'Raising weight piglets',
  [IntlKeys.vriAverageRoundLengthDays]: 'Gemiddeld aantal ligdagen',
  [IntlKeys.vriAveragePigsCount]: 'Gemiddeld aantal vleesvarken',
  [IntlKeys.vriEpgEuro]: 'EPG (€)',
  [IntlKeys.vriGrowth]: 'Groei (gr/dag)',

  // invoices

  [InvoicesKeys.currencyCode]: 'Valuta code',
  [InvoicesKeys.overviewType]: 'Overzicht Type',
  [InvoicesKeys.invoiceNumber]: 'Factuurnummer',
  [InvoicesKeys.invoiceDate]: 'Factuur datum',
  [InvoicesKeys.uniqueMessageNumber]: 'Uniek Berichtnummer',
  [InvoicesKeys.supplierName]: 'Naam Leverancier',
  [InvoicesKeys.supplierStreet]: 'Postadres Straat Leverancier',
  [InvoicesKeys.supplierHouseNumber]: 'Huisnummer Leverancier',
  [InvoicesKeys.supplierPlace]: 'Postadres Plaats Leverancier',
  [InvoicesKeys.supplierPostCode]: 'Postadres Postcode Leverancier',
  [InvoicesKeys.supplierEANAddressCode]: 'EAN Adres Code Leverancier',
  [InvoicesKeys.supplierBankAccount]: 'Bankrekeningnummer Leverancier',
  [InvoicesKeys.supplierIban]: 'IBAN Leverancier',
  [InvoicesKeys.supplierVatNumber]: 'Internationaal BTW-nummer Leveranciers',
  [InvoicesKeys.customerName]: 'Klantnaam',
  [InvoicesKeys.customerStreetAddress]: 'Adres Klant',
  [InvoicesKeys.customerPostalAddressPlace]: 'Postadres Klant',
  [InvoicesKeys.customerRelationNumber]: 'Relatienummer Klant',
  [InvoicesKeys.deliveries]: 'Leveringen',

  // invoice details

  [InvoicesKeys.invoice]: 'Factuur',
  [InvoicesKeys.unit]: 'Eenheid',
  [InvoicesKeys.invoiceMessageId]: 'Factuurbericht-ID',
  [InvoicesKeys.lineNumber]: 'Regelnummer',
  [InvoicesKeys.deliveryDate]: 'Bezorgdatum',
  [InvoicesKeys.deliveryNoteNumber]: 'Afleverbonnummer',
  [InvoicesKeys.deliveryNoteLineNumber]: 'Regelnummer leveringsnota',
  [InvoicesKeys.productQuantity]: 'Product kwantiteit',
  [InvoicesKeys.productArticleCode]: 'Artriekelcode product',
  [InvoicesKeys.productDescription]: 'Productomschrijving',
  [InvoicesKeys.productArticleGroupCode]: 'Artikelgroep Code Product',
  [InvoicesKeys.productArticleGroupCodeDescription]: 'Omschrijving artikelgroep Code Product',
  [InvoicesKeys.productEnergeticValue]: 'Energiewaarde product',

  // slaughter

  [IntlKeys.slaughterFarmerId]: 'Leverancier Relatienummer',
  [IntlKeys.slaughterMessageDate]: 'Datum bericht',
  [IntlKeys.slaughterCurrency]: 'Valuta',
  [IntlKeys.slaughterSlaughterHouseId]: 'Slachterij ID',
  [IntlKeys.slaughterSlaughterDate]: 'Slachtdatum',
  [IntlKeys.slaughterSlaughters]: 'Slaughters',
  [IntlKeys.slaughterSlaughterPlaceId]: 'Slachtlocatienummer',
  [IntlKeys.slaughterUbnList]: 'Leverancier ID',
  [IntlKeys.slaughterMessageSequenceNumber]: 'Volgnummer bericht',
  [IntlKeys.slaughterAnimalsSuppliedCount]: 'Aantal dieren geleverd',

  // slaughter details

  [IntlKeys.slaughterSlaughterMessageId]: 'Slachtbericht ID',
  [IntlKeys.slaughterSlaughterMessage]: 'Slachtbericht',
  [IntlKeys.slaughterSlaughterKey]: 'Slachterij ID',
  [IntlKeys.slaughterAnimalType]: 'Slachterij diersoort',
  [IntlKeys.slaughterCarcassCode]: 'Karkas afwijkingen',
  [IntlKeys.slaughterAnimalWeight]: 'Geslacht gewicht (kg)',
  [IntlKeys.slaughterOrganCode]: 'Orgaan afwijkingen',
  [IntlKeys.slaughterMuscleThickness]: 'Spierdikte (mm)',
  [IntlKeys.slaughterFatThickness]: 'Spekdikte (mm)',
  [IntlKeys.slaughterAfterCorrectionPigAmount]: 'Bedrag varken na correctie',
  [IntlKeys.slaughterContainerNumber]: 'Dieridentificatie',
  [IntlKeys.slaughterMeat]: 'Vleespercentage',
  [IntlKeys.slaughterAnimalIdentificationType]: 'Dieridentificatie type',

  // slaughter price corrections

  [IntlKeys.slaughterPriceCorrections]: 'Correcties op slachtprijzen',

  [IntlKeys.slaughterPCFinancialResultId]: 'Financieel resultaat-ID',
  [IntlKeys.slaughterPCFinancialResultDateTime]: 'Financieel resultaat datum tijd',
  [IntlKeys.slaughterPCSlaughterHouseTypeCode]: 'Code type slachthuis',
  [IntlKeys.slaughterPCSlaughterHouseValue]: 'Waarde slachthuis',
  [IntlKeys.slaughterPCTraderTypeCode]: 'Code van het type handelaar',
  [IntlKeys.slaughterPCTraderValue]: 'Handelaar waarde',
  [IntlKeys.slaughterPCAnimalTypeCode]: 'Typecode dier',
  [IntlKeys.slaughterPCAnimalValue]: 'Dierlijke waarde',
  [IntlKeys.slaughterPCNumberOfAnimals]: 'Aantal Dieren',
  [IntlKeys.slaughterPCCurrencyCode]: 'Valuta code',

  // slaughter price corrections

  [IntlKeys.slaughterChargeOrPremiums]: 'Slachtkosten of premies',

  [IntlKeys.slaughterCOPCodeListId]: 'Code lijst ID',
  [IntlKeys.slaughterCOPCode]: 'Code',
  [IntlKeys.slaughterCOPBaseCode]: 'Basiscode',
  [IntlKeys.slaughterCOPAmount]: 'Bedrag',
  [IntlKeys.slaughterCOPVatPercentage]: 'BTW-percentage',
  [IntlKeys.slaughterCOPVatAmount]: 'BTW bedrag',

  // genetics

  [GeneticKeys.ubn]: 'Klantnummer',
  [GeneticKeys.semenProducerUbn]: 'Leveranciersnummer',
  [GeneticKeys.deliveryDate]: 'Leverdatum',
  [GeneticKeys.maleType]: 'Type eindbeer',
  [GeneticKeys.maleAmount]: 'Doses eindbeer',
  [GeneticKeys.femaleType]: 'Type fokkerijbeer',
  [GeneticKeys.femaleAmount]: 'Doses fokkerijbeer',

  // organizations

  [IntlKeys.createOrganization]: 'Maak een nieuwe Organisatie aan',
  [IntlKeys.organizationPlural]: 'Organisaties',
  [IntlKeys.organizationChangeBanner]: 'Click to change banner',
  [IntlKeys.invalidFileExtension]: 'Je mag geen "{extension}"-bestanden uploaden, toegestane types: {whiteList}',
  [IntlKeys.draftPassports]: 'paspoort(en) niet verzonden',
  [IntlKeys.submittedPassports]: 'paspoort(en) in afwachting van goedkeuring',
  [IntlKeys.approvedPassports]: 'paspoort(en) goedgekeurd',
  [IntlKeys.rejectedPassports]: 'paspoort(en) afgekeurd',
  //
  [IntlKeys.organizationsKvkName]: 'KvK Bedrijfsnaam',
  [IntlKeys.organizationsCertificatesOther]: 'Certificaten (anders)',
  [IntlKeys.organizationsGeneticSuppliers]: 'Genetica Leverancier',
  [IntlKeys.organizationsGeneticSupplierOther]: 'Genetica Leverancier (anders)',
  [IntlKeys.organizationsKiStations]: 'KI Station',
  [IntlKeys.organizationsKiStationOther]: 'KI Station (anders)',
  [IntlKeys.organizationsSowLines]: 'Zeugenlijnen',
  [IntlKeys.organizationsSowLineOther]: 'Zeugenlijn (anders)',
  [IntlKeys.organizationsBreedingProgram]: 'Rotatie Kruising',
  [IntlKeys.organizationsSireLines]: 'Berenlijn',
  [IntlKeys.organizationsSireLineOther]: 'Berenlijn (anders)',
  [IntlKeys.organizationsVeterinaryPractice]: 'Dierenartspraktijk',
  [IntlKeys.organizationsVeterinaryPracticeOther]: 'Dierenartspraktijk (anders)',
  [IntlKeys.organizationsFeedSupplier]: 'Voerleverancier',
  [IntlKeys.organizationsFeedSupplierOther]: 'Voerleverancier (anders)',
  [IntlKeys.organizationsFmsNames]: 'Farm Management Systeem',
  [IntlKeys.organizationsFmsModuleAgrisysts]: 'FMS Module Agrisyst',
  [IntlKeys.organizationsFmsModuleAgroCoops]: 'FMS Module Agroscoop',
  [IntlKeys.organizationsFmsModuleAgrovisions]: 'FMS Module Agrovision',
  [IntlKeys.organizationsFeedingSystems]: 'Voerinstallatie',
  [IntlKeys.organizationsFeedingSystemOther]: 'Voerinstallatie (anders)',
  [IntlKeys.organizationsClimateSystems]: 'Klimaatsysteem',
  [IntlKeys.organizationsClimateSystemOther]: 'Klimaatsysteem (anders)',
  [IntlKeys.organizationsCompanyName]: 'Bedrijfsnaam',
  [IntlKeys.organizationsAddressLine1]: 'Adres Regel 1',
  [IntlKeys.organizationsAddressLine2]: 'Adres Regel 2',
  [IntlKeys.organizationsPostalCode]: 'Postcode',
  [IntlKeys.organizationsCity]: 'Stad',
  [IntlKeys.organizationsTelephoneNumber]: 'Telefoon Nummer',
  [IntlKeys.organizationsVatNumber]: 'BTW Nummer',
  [IntlKeys.organizationsUsers]: 'Organisatie Gebruikers',
  [IntlKeys.organizationsPermissionProfile]: 'Toestemming Profiel',

  // organizations userPassports

  [IntlKeys.organizationsType]: 'Type',
  [IntlKeys.organizationsConcepts]: 'Concepts',
  [IntlKeys.organizationsKvk]: 'KVK',
  [IntlKeys.organizationsGln]: 'GLN',
  [IntlKeys.organizationsUbn]: 'UBN',
  [IntlKeys.organizationsVionRelationNumber]: 'Klant Relatie Nummer',
  [IntlKeys.organizationsName]: 'Naam',
  [IntlKeys.organizationsRegistrationName]: 'Naam registratie (KVK)',
  [IntlKeys.organizationsVatNum]: 'BTW nr.',
  [IntlKeys.organizationsAddressStreet]: 'Adres: straat',
  [IntlKeys.organizationsAddressPostcode]: 'Adres: postcode',
  [IntlKeys.organizationsAddressPlace]: 'Adres: plaats',
  [IntlKeys.organizationsPhone]: 'Telefoon',
  [IntlKeys.organizationsEmail]: 'Contact Email',
  [IntlKeys.organizationsCertificationAuthority]: 'Certificeringsinstantie',
  [IntlKeys.organizationsFeedSuppliers]: 'Voer leveranciers',
  [IntlKeys.organizationsVeterinarian]: 'Dierenarts',
  [IntlKeys.organizationsTypeOfCompany]: 'Organisatie Type',

  // delivery messages

  [IntlKeys.deliveryHeaderUniqueMessageNumber]: 'Header Uniek Berichtnummer',
  [IntlKeys.deliveryHeaderAssociationAssignedCode]: 'Header Association Assigned Code',
  [IntlKeys.deliveryHeaderMessageSentDate]: 'Header Verzenddatum bericht',
  [IntlKeys.deliveryHeaderSenderId]: 'Header ZenderId',
  [IntlKeys.deliveryHeaderMessageVersionNumber]: 'Header Versienummer bericht',
  [IntlKeys.deliverySupplierNumber]: 'Leveranciersnummer',
  [IntlKeys.deliverySupplierName]: 'Leveranciersnaam',
  [IntlKeys.deliverySupplierStreetName]: 'Leveranciersstraatnaam',
  [IntlKeys.deliverySupplierHouseNumber]: 'Leveranciershuisnummer',
  [IntlKeys.deliverySupplierPostalCode]: 'Leverancierspostcode',
  [IntlKeys.deliverySupplierCityName]: 'Leveranciersplaatsnaam',
  [IntlKeys.deliverySupplierCountry]: 'Leveranciersland',
  [IntlKeys.deliverySupplierPhoneNumber]: 'Leverancierstelefoonnummer',
  [IntlKeys.deliverySupplierEmailAddress]: 'Leveranciersemailadres',
  [IntlKeys.deliverySupplierUrl]: 'LeveranciersURL',
  [IntlKeys.deliverySupplierVat]: 'LeveranciersVAT',
  [IntlKeys.deliveryCustomerNumber]: 'Klantnummer',
  [IntlKeys.deliveryCustomerName]: 'Klantnaam',
  [IntlKeys.deliveryCustomerStreetName]: 'Klantstraatnaam',
  [IntlKeys.deliveryCustomerHouseNumber]: 'Klanthuisnummer',
  [IntlKeys.deliveryCustomerPostalCode]: 'Klantpostcode',
  [IntlKeys.deliveryCustomerCityName]: 'Klantplaatsnaam',
  [IntlKeys.deliveryCustomerCountry]: 'Klantland',

  // delivery segment

  [IntlKeys.deliveryUniqueKeyReceipt]: 'Unieke key bon',
  [IntlKeys.deliveryReceiptNumber]: 'Bon nummer',
  [IntlKeys.deliveryReceiptDate]: 'Bon datum',
  [IntlKeys.deliveryReceiptPrintDate]: 'Bon afdrukdatum',
  [IntlKeys.deliveryUbnNumber]: 'UBN Nummer',
  [IntlKeys.deliveryStreetnameDeliveryAddress]: 'Straatnaam afleveradres',
  [IntlKeys.deliveryCityNameDeliveryAddress]: 'Plaatsnaam afleveradres',
  [IntlKeys.deliveryPostcodeDeliveryAddress]: 'Postcode afleveradres',
  [IntlKeys.deliveryCountryDeliveryAddress]: 'Land afleveradres',
  [IntlKeys.deliveryOrderNumber]: 'Ordernummer',
  [IntlKeys.deliveryDeliveryMethod]: 'Bezorgwijze',
  [IntlKeys.deliveryBrsNumber]: 'BRS-nummer',

  // delivery segment contents

  [IntlKeys.deliveryNoteLineNumber]: 'Leveringsbon regelnr',
  [IntlKeys.deliveryArticleNumber]: 'Artikelnummer',
  [IntlKeys.deliveryArticleDescription]: 'Artikelomschrijving',
  [IntlKeys.deliveryNetQuantityOfProductDelivered]: 'Netto hoeveelheid geleverd product',
  [IntlKeys.deliveryQuantityUnit]: 'Hoeveelheid eenheid',
  [IntlKeys.deliveryArticleGroupCode]: 'Artikelgroepcode',
  [IntlKeys.deliveryQuantityOfProductOrdered]: 'Hoeveelheid product besteld',
  [IntlKeys.deliveryBranchNumber]: 'Vestigingsnummer',
  [IntlKeys.deliveryBranchName]: 'Vestigingsnaam',
  [IntlKeys.deliveryGmpNumber]: 'GMP-nummer',
  [IntlKeys.deliveryInvoiceNumber]: 'Factuurnummer',
  [IntlKeys.deliveryBaseContent]: 'Grondslaggehalte',
  [IntlKeys.deliveryNutrientN]: 'Nutrient-N',
  [IntlKeys.deliveryNutrientP]: 'Nutrient-P',
  [IntlKeys.deliveryNutrientRe]: 'Nutrient-RE',
  [IntlKeys.deliveryNutrientFos]: 'Nutrient-FOSFAATP2O5',
  [IntlKeys.deliveryNutrientVem]: 'Nutrient-VEM',
  [IntlKeys.deliveryNutrientVevi]: 'Nutrient-VEVI',
  [IntlKeys.deliveryEnergyValueEw]: 'EW-waarde',
  [IntlKeys.deliveryNutrientDs]: 'Nutrient-DS',
  [IntlKeys.deliveryNutrientNdf]: 'Nutrient-NDF',
  [IntlKeys.deliveryNutrientStarch]: 'Nutrient-Zetmeel',
  [IntlKeys.deliveryNutrientEf00]: 'Nutrient-EF00ProcSnijmais',
  [IntlKeys.deliveryNutrientEf40]: 'Nutrient-EF40ProcSnijmais',
  [IntlKeys.deliveryNutrientEf80]: 'Nutrient-EF80ProcSnijmais',
  [IntlKeys.deliveryNutrientCo2]: 'Nutrient-CO2EmOffFarm',
  [IntlKeys.deliveryNutrientVCos]: 'Nutrient-VCos',
  [IntlKeys.deliveryNutrientVCre]: 'Nutrient-VCre',
  [IntlKeys.deliveryAnimalCategoryCode]: 'Diercategoriecode',
  [IntlKeys.deliveryFeedTypeCode]: 'Voersoortcode',
  [IntlKeys.deliveryFeedTypeCodeKlw]: 'Voersoortcode KLW',
  [IntlKeys.deliveryRas]: 'RAS',

  // veterinarian data

  [IntlKeys.veterinaryExportDate]: 'Datum',
  [IntlKeys.veterinaryUbn]: 'UBN',
  [IntlKeys.veterinaryCompanyName]: 'Naam',
  [IntlKeys.veterinaryStreetAddress]: 'Adres Straat',
  [IntlKeys.veterinaryPlaceAddress]: 'Adres Postcode',
  [IntlKeys.veterinaryPeriod]: 'Periode',
  [IntlKeys.veterinaryAnimalCategory]: 'Diercategorie',
  [IntlKeys.veterinaryDddTm]: 'DDD van periode',
  [IntlKeys.veterinaryDeliveryDate]: 'Afleverdatum',
  [IntlKeys.veterinaryRegistrationDate]: 'Registratiedatum',
  [IntlKeys.veterinaryVeterinarianName]: 'Naam Dierenarts',
  [IntlKeys.veterinaryEan]: 'EAN',
  [IntlKeys.veterinaryRegistrationNumberNl]: 'REG NL',
  [IntlKeys.veterinaryChoice]: 'Keuze',
  [IntlKeys.veterinaryProductName]: 'Naam Middel',
  [IntlKeys.veterinaryPackagesCount]: 'Aantal Verpakkingen',
  [IntlKeys.veterinaryCapacity]: 'Inhoud ml/gr/stuks',
  [IntlKeys.veterinaryTotal]: 'Totaal',
  [IntlKeys.veterinaryDosingFactor]: 'Doseringsfactor per ml/gr per 100 kg per Dag',
  [IntlKeys.veterinaryAvgAnimalsNumber]: 'Gemiddeld Aantal Dieren',
  [IntlKeys.veterinaryWeightPerAnimal]: 'Rekengewicht per Dier',
  [IntlKeys.veterinaryDddDaily]: 'Dierdagdoseringen per Leveringsregel',

  // movement events

  [MovementEventsKeys.eventId]: 'Event ID',
  [MovementEventsKeys.eventType]: 'Event Type',
  [MovementEventsKeys.eventDateTime]: 'Event Date',
  [MovementEventsKeys.animalCategory]: 'Diercategorie',
  [MovementEventsKeys.animalCount]: 'Aantal dieren',
  [MovementEventsKeys.transportId]: 'Vervoer ID',
  [MovementEventsKeys.groupDate]: 'Group Data',
  [MovementEventsKeys.kvk]: 'KVK',
  [MovementEventsKeys.ubn]: 'UBN',
  [MovementEventsKeys.destination]: 'Bestemming',
  [MovementEventsKeys.origin]: 'Oorsprong',
  [MovementEventsKeys.eventLocationId]: 'Event Location ID',
  [MovementEventsKeys.eventIdMatch]: 'Gebeurtenis-ID Match',

  // farm home
  [FarmHomeKeys.farmProfile]: 'Bedrijfsprofiel',
  [IntlKeys.farmStableData]: 'Stal gegevens',
  [FarmHomeKeys.stableRegistrations]: 'Stal registraties',

  // farm events

  [FarmEventsKeys.ubn]: 'UBN',
  [FarmEventsKeys.stableName]: 'Stal naam',
  [FarmEventsKeys.type]: 'Type',
  [FarmEventsKeys.action]: 'Action',
  [FarmEventsKeys.lastModifiedBy]: 'Laatst gewijzigd door',

  // farm reports

  [FarmReportKeys.supportTitle]: 'Opleg',
  [FarmReportKeys.deathsTitle]: 'Uitval',
  [FarmReportKeys.deliveryTitle]: 'Aflevering',
  [FarmReportKeys.feedConsumptionTitle]: 'Voerverbruik',
  [FarmReportKeys.stable]: 'Stal',
  [FarmReportKeys.department]: 'Afdeling',
  [FarmReportKeys.originUbn]: 'Herkomst UBN Vermeerderaar',
  [FarmReportKeys.amount]: 'Aantal',
  [FarmReportKeys.weight]: 'Gewicht',
  [FarmReportKeys.date]: 'Datum',
  [FarmReportKeys.geneticMother]: 'Genetica Moeder',
  [FarmReportKeys.geneticFather]: 'Genetica Vader',
  [FarmReportKeys.vaccination]: 'Vaccinatie',
  [FarmReportKeys.notes]: 'Notities (optioneel)',
  [FarmReportKeys.causeOfDeath]: 'Doodsoorzaak',
  [FarmReportKeys.actualWeightOfFeedType]: 'Gevoerde Kg per Voer Type',
  [FarmReportKeys.startFeed]: 'Start Voer',
  [FarmReportKeys.growthFeed]: 'Groei Voer',
  [FarmReportKeys.finishFeed]: 'Eind Voer',
  [FarmReportKeys.departmentNotFound]: 'Maak eerst een stal aan om verder te gaan',

  // farm stables

  [FarmStablesKeys.selectLocationTitle]: 'Selecteer locatie',
  [FarmStablesKeys.stableNumber]: 'Aantal stallen op uw locatie',

  [FarmStablesKeys.modifyStableTitle]: 'Maken/Bewerken Stal {id}',
  [FarmStablesKeys.stableName]: 'Stal Naam',
  [FarmStablesKeys.animalCategory]: 'Diercategorie',
  [FarmStablesKeys.weaningPiglets]: 'Speenbiggen',
  [FarmStablesKeys.sows]: 'Zeugen',
  [FarmStablesKeys.finishers]: 'Vleesvarkens',
  [FarmStablesKeys.feeding]: 'Voersysteem',
  [FarmStablesKeys.feedType]: 'Type voer',
  [FarmStablesKeys.dryFoodLimited]: 'Droogvoer Beperkt',
  [FarmStablesKeys.dryFoodUnlimited]: 'Droogvoer Onbeperkt',
  [FarmStablesKeys.liquidFoodLimited]: 'Brijvoer Beperkt',
  [FarmStablesKeys.liquidFoodUnlimited]: 'Brijvoer Onbeperkt',
  [FarmStablesKeys.deleteStable]: 'Stal verwijderen',
  [FarmStablesKeys.editDepartments]: 'Afdelingen Bewerken',

  [FarmStablesKeys.departments]: 'Afdelingen',
  [FarmStablesKeys.departmentsOptionalNote]: 'Optioneel, alleen wanneer vleesvarkens zijn geselecteerd',

  [FarmStablesKeys.numberOfDepartments]: 'Aantal afdelingen',
  [FarmStablesKeys.placesPerDepartment]: 'Aantal plaatsen per afdeling',
  [FarmStablesKeys.placesPerDepartmentAverage]: 'Aantal plaatsen per afdeling (gemiddeld)',
  [FarmStablesKeys.editDepartmentButtonText]: 'kunt u gebruiken wanneer de aantallen per afdeling verschillen',

  // joindata

  [IntlKeys.purposePlural]: 'Machtigingen',
  [IntlKeys.participationPlural]: 'Machtigingen',
  [IntlKeys.requestParticipations]: 'Machtiging aanvragen',
  [IntlKeys.requestedParticipations]: 'Machtiging Aangevraagd',
  [IntlKeys.unmatchedOrganizations]: 'Niet gematchte organisaties',

  [IntlKeys.joindataPurposeName]: 'Naam machtiging',
  [IntlKeys.joindataParticipationGrantedOn]: 'Toegekend op',
  [IntlKeys.joindataParticipationStartDate]: 'Startdatum machtiging',
  [IntlKeys.joindataParticipationStatus]: 'Status machtiging',
  [IntlKeys.joindataParticipationValid]: 'Machtiging geldig',
  [IntlKeys.joindataRestrictionType]: 'Beperkingstype',
  [IntlKeys.joindataRestrictionDescription]: 'Beperkingsbeschrijving',
  [IntlKeys.joindataRestrictionStart]: 'Start beperking',
  [IntlKeys.joindataContentStartDate]: 'Begindatum van inhoud',
  [IntlKeys.joindataExternalParticipationId]: 'Extern deelname-ID',

  // Purpose Details

  [IntlKeys.purposeStatus]: 'Status machtiging',
  [IntlKeys.purposeDetails]: 'Datapunten',
  [IntlKeys.farmManagementData]: 'Farm Management Data',
  [IntlKeys.deliveryNotices]: 'Levererings-Berichten',
  //
  [IntlKeys.purposeDashboardP1]: 'Kwaliteitscertificaten van controle-instanties',
  [IntlKeys.purposeDashboardP2]: 'Elektronisch slachtbericht',
  [IntlKeys.purposeDashboardP3]: 'Sperma leveringen van de geneticaleverancier',
  [IntlKeys.purposeDashboardP4]: 'Voer leveringsberichten',
  [IntlKeys.purposeDashboardP5]: 'I&R Dierverplaatsingsberichten',
  [IntlKeys.purposeDashboardP6]: 'Leveringen van diergeneesmiddelen en vaccins',
  [IntlKeys.purposeDashboardP7]: 'Farmmanagement systeem',
  [IntlKeys.purposeVriToolP1]: 'Aantal opgelegde dieren',
  [IntlKeys.purposeVriToolP2]: 'Aantal afgeleverde dieren',
  [IntlKeys.purposeVriToolP3]: 'Aantal dode dieren',
  [IntlKeys.purposeVriToolP4]: 'Datum van opleggen',
  [IntlKeys.purposeVriToolP5]: 'Datum van afleveren',
  [IntlKeys.purposeVriToolP6]: 'Gemiddelde opleggewicht',
  [IntlKeys.purposeVriToolP7]: 'Voerlevering aan varkenshouder',
  [IntlKeys.purposeVriToolP8]: 'Samenstelling voedertype',
  [IntlKeys.purposeVriToolP9]: 'Gemiddeld geslacht gewicht',
  [IntlKeys.purposeVriToolP10]: 'Orgaanafwijkingen',
  [IntlKeys.purposeVriToolP11]: 'Karkasafwijkingen',
  [IntlKeys.purposeVriToolP12]: 'Geslacht gewicht',
  [IntlKeys.purposeVriToolP13]: 'Spierdikte',
  [IntlKeys.purposeVriToolP14]: 'Spekdikte',
  [IntlKeys.purposeVriToolP15]: 'Vleespercentage',
  [IntlKeys.purposeVriToolP16]: 'Gewicht correctie',
  [IntlKeys.purposeVriToolP17]: 'Gem spier correctie',
  [IntlKeys.purposeVriToolP18]: 'Gem spek correctie',
  [IntlKeys.purposeVriToolP19]: 'Gem vlees% correctie',
  [IntlKeys.purposeVriToolP20]: 'Gem Seksetoeslag',
  [IntlKeys.purposeVriToolP21]: 'Gem concept toeslag',
  [IntlKeys.purposeVriFarmingGroupP1]: 'Certificeringen',
  [IntlKeys.purposeVriFarmingGroupP2]: 'Aankoop vaccins',
  [IntlKeys.purposeVriFarmingGroupP3]: 'Aankoop medicijnen',
  [IntlKeys.purposeVriFarmingGroupP4]: 'DierDagDosering',
  [IntlKeys.purposeVriFarmingGroupP5]: 'Certificering voedertype',
  [IntlKeys.purposeVriFarmingGroupP6]: 'Voerlevering samenstelling voedertype',
  [IntlKeys.purposeVriFarmingGroupP7]: 'Voerlevering aan varkenshouder',
  [IntlKeys.purposeVriFarmingGroupP8]: 'Herkomst voerlevering aan varkenshouder',
  [IntlKeys.purposeVriFarmingGroupP9]: 'Herkomst grondstoffen',
  [IntlKeys.purposeVriFarmingGroupP10]: 'Herkomstgegevens fokmateriaal',
  [IntlKeys.purposeVriFarmingGroupP11]: 'Herkomst biggen',
  [IntlKeys.purposeVriFarmingGroupP12]: 'Herkomst vleesvarkens',
  [IntlKeys.purposeVriFarmingGroupP13]: 'Locatie slachterij',
  [IntlKeys.purposeVriFarmingGroupP14]: 'Spermatype eindbeer',
  [IntlKeys.purposeVriFarmingGroupP15]: 'Spermatype fokkerij',
  [IntlKeys.purposeVriFarmingGroupP16]: 'Leverdatum sperma bij vermeerderaar/gesloten bedrijf',
  [IntlKeys.purposeVriFarmingGroupP17]: 'Aantal dosissen',
  [IntlKeys.purposeVisionDashboardGroupP1]: 'Certificeringen (e.g. IKB, Beter Leven Keurmerk)',
  [IntlKeys.purposeVisionDashboardGroupP2]: 'Beoordeling van controle resultaten door de controle instantie',
  [IntlKeys.purposeVisionDashboardGroupP3]: 'Elektronisch slachtbericht',
  [IntlKeys.purposeVisionDashboardGroupP4]: 'Slachtcorrectiebericht',
  [IntlKeys.purposeVisionDashboardGroupP5]: 'Locatiegegevens van de herkomst van sperma per levering',
  [IntlKeys.purposeVisionDashboardGroupP6]: 'Spermatype eindbeer',
  [IntlKeys.purposeVisionDashboardGroupP7]: 'Spermatype fokkerij',
  [IntlKeys.purposeVisionDashboardGroupP8]: 'Leverdatum sperma bij vermeerderaar/gesloten bedrijf',
  [IntlKeys.purposeVisionDashboardGroupP9]: 'Aantal dosissen',
  [IntlKeys.purposeVisionDashboardGroupP10]: 'Certificering voedertype',
  [IntlKeys.purposeVisionDashboardGroupP11]: 'Voerlevering samenstelling voedertype (grondstoffen energiewaarde)',
  [IntlKeys.purposeVisionDashboardGroupP12]: 'Voerlevering aan varkenshouder (type voer, kg)',
  [IntlKeys.purposeVisionDashboardGroupP13]: 'Herkomst voerlevering aan varkenshouder (GLN voerfabriek)',
  [IntlKeys.purposeVisionDashboardGroupP14]: 'Herkomstgegevens fokmateriaal (UBN/GLN of locatie (herkomst gelten-> vermeerderaar))',
  [IntlKeys.purposeVisionDashboardGroupP15]: 'Herkomst biggen = UBN/GLN of locatie (aanvoer vleesvarkensbedrijf)',
  [IntlKeys.purposeVisionDashboardGroupP16]: 'Herkomst vleesvarkens = UBN/GLN (aanvoer slachterij)',
  [IntlKeys.purposeVisionDashboardGroupP17]: 'Locatie slachterij (UBN/GLN)',
  [IntlKeys.purposeVisionDashboardGroupP18]: 'Aankoop vaccins',
  [IntlKeys.purposeVisionDashboardGroupP19]: 'Aankoop medicijnen',
  [IntlKeys.purposeVisionDashboardGroupP20]: 'DierDagDosering',
  [IntlKeys.purposeVisionDashboardGroupP21]: 'Ritinformatie van het transport naar de slachterij',
  [IntlKeys.purposeVisionDashboardGroupP22]: 'Kengetallen uit het Farmmanagement systeem',
  [IntlKeys.purposeVisionDashboardGroupP23]: 'Ingevoerde gegevens uit het Invoerportaal',
  [IntlKeys.titleKwaliteitscertificaten]: 'Kwaliteitscertificaten',
  [IntlKeys.titleSlachtAndSaldo]: 'Slacht & Saldo correcties',
  [IntlKeys.titleDierverplaatsingsberichten]: 'I&R Dierverplaatsingsberichten',
  [IntlKeys.titleDierenartsData]: 'Dierenarts Data',
  [IntlKeys.titleTransports]: 'Transporten',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP1]: 'Gemiddeld aantal geproduceerde biggen per zeug per jaar',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP2]: 'RVO Dierverplaatsingen',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP3]: 'Totale aantal verkochte zeugen en biggen per jaar (voor de toewijzing van de CO2-footprint aan biggen en zeugen wordt de verhouding tussen de economische waarde van biggen en zeugen gebruikt)',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP4]: 'Gemiddeld aantal aanwezige zeugen en biggen',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP5]: 'RVO Mesttransporten',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP6]: 'Type mestopslag',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP7]: 'RAV codes (type huisvesting)',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP8]: 'Type elektriciteit',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP9]: 'Elektriciteitsverbruik en -productie',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP10]: 'Gasverbruik',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP11]: 'Waterverbruik (via de meter en eigen bron)',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP12]: 'Totale energieverbruik gesplitst voor zakelijk en privé',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP13]: 'Gemiddeld voerverbruik per aanwezige zeug',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP14]: 'Slachtresultaten - Totaal aantal kilo (levend gewicht) geleverd aan slachterij',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP15]: 'RVO Dierverplaatsingen - Aantal leveringen van biggen aan vleesvarkensbedrijf',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP16]: 'Gemiddeld gewicht van aangeleverde biggen op vleesvarkensbedrijf',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP17]: 'Gemiddeld aantal vleesvarkens',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP18]: 'Voerconversie',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP19]: 'Groei per dag',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP20]: 'Uitval van dieren',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP21]: 'RVO Mesttransporten',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP22]: 'Type mestopslag',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP23]: 'RAV codes (type huisvesting)',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP24]: 'Type elektriciteit',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP25]: 'Elektriciteitsverbruik en -productie',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP26]: 'Gasverbruik',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP27]: 'Waterverbruik (via de meter en eigen bron)',
  [IntlKeys.purposeСo2FootprintPigFarmingGroupP28]: 'Totale energieverbruik gesplitst voor zakelijk en privé',
  [IntlKeys.titleDataFromSawFarming]: 'Gegevens uit de zeugenhouderij',
  [IntlKeys.titleDataFromFatteningPigIndustry]: 'Gegevens uit de vleesvarkenshouderij',
  [IntlKeys.purposeСo2FootprintFeedOriginGroupP1]: 'Gegevens voerleverancier CO2-emissie off Farm',
  [IntlKeys.purposeСo2FootprintFeedOriginGroupP2]: 'Herkomst en hoeveelheid geleverde voergrondstoffen',
  [IntlKeys.purposeСo2FootprintFeedOriginGroupP3]: 'Aantal voerleveringen',
  [IntlKeys.purposeСo2FootprintFeedOriginGroupP4]: 'Verteringscoëfficiënt organische stof (%) per type voer',
  [IntlKeys.purposeСo2FootprintFeedOriginGroupP5]: 'Ruw as (%) per type voer',
  [IntlKeys.purposeСo2FootprintFeedOriginGroupP6]: 'Ruw eiwit (%) per type voer',
  [IntlKeys.purposeСo2FootprintFeedOriginGroupP7]: 'Verteringscoëfficiënt ruw eiwit (%) per type voer',
  [IntlKeys.titleFeedDateFromFeedSupplier]: 'Voergegevens van voerleverancier',
  [IntlKeys.titleFeedDataFromFeedSupplierForCalculationCO2Emissions]: 'Voergegevens van voerleverancier voor de berekening van CO2-emissies uit mest',

  // Transports

  [IntlKeys.transports]: 'Transporten',
  [IntlKeys.transportLoadings]: 'Transport Laden',
  [IntlKeys.transportLoadingsShort]: 'Laden',
  [IntlKeys.transportUnloadings]: 'Transport Lossen',
  [IntlKeys.transportUnloadingsShort]: 'Lossen',

  [IntlKeys.transportsReferenceId]: 'Transportreferentie-ID',
  [IntlKeys.transportsPartyIdTypeCode]: 'Party Id Type Code',
  [IntlKeys.transportsPartyIdValue]: 'Waarde Party Id ',
  [IntlKeys.transportsLogisticServiceProviderName]: 'Naam logistieke dienstverlener',

  [IntlKeys.transportsPlannedArrivalTime]: 'Geplande aankomsttijd',
  [IntlKeys.transportsActualArrivalTime]: 'Werkelijke aankomsttijd',
  [IntlKeys.transportsStartLoadingTime]: 'Starttijd Laden',
  [IntlKeys.transportsEndLoadingTime]: 'Eindtijd Laden',
  [IntlKeys.transportsPlannedDepartureTime]: 'Geplande vertrektijd',
  [IntlKeys.transportsActualDepartureTime]: 'Werkelijke vertrektijd',
  [IntlKeys.transportsLocationIdTypeCode]: 'Aantal dieren',
  [IntlKeys.transportsLocationIdValue]: 'Locatie-ID-typecode',
  [IntlKeys.transportsLocationName]: 'Locatie-ID-waarde',

  // farmer input

  [IntlKeys.farmerInputCategory]: 'Categorie',
  [IntlKeys.farmerInputIdCode]: 'ID Code',
  [IntlKeys.farmerInputIdType]: 'ID Type',
  [IntlKeys.farmerInputReportDate]: 'Rapportdatum vanaf',
  [IntlKeys.farmerInputStatus]: 'Status',
  [IntlKeys.farmerInputUpdatedBy]: 'Bijgewerkt door',
  [IntlKeys.farmerInputUpdatedOn]: 'Aangepast op',
  [IntlKeys.farmerInputManureFileName]: 'Mest bestandsnaam',
  [IntlKeys.farmerInputAverageNumberOfSowsPerPeriod]: 'Gemiddeld aantal aanwezige zeugen',
  [IntlKeys.farmerInputAverageNumberOfRearingSowsPerPeriod]: 'Gemiddeld aantal aanwezige opfokzeugen',
  [IntlKeys.farmerInputAverageNumberOfPigletsPerPeriod]: 'Gemiddeld aantal aanwezige gespeende biggen',
  [IntlKeys.farmerInputAverageNumberOfFinishingPigsPerPeriod]: 'Gemiddeld aantal aanwezige vleesvarkens',
  [IntlKeys.farmerInputTotalPriceSoldSows]: 'Totaal aantal verkochte zeugen (afgevoerd van deze locatie)',
  [IntlKeys.farmerInputTotalPriceSoldPiglets]: 'Totaal aantal verkochte biggen (afgevoerd van deze locatie)',
  [IntlKeys.farmerInputAverageWeightPigletsPurchased]: 'Gemiddeld opleggewicht biggen',
  [IntlKeys.farmerInputAverageNumberOfPigletsProduced]: 'Gemiddeld aantal grootgebrachte biggen per zeug',
  [IntlKeys.farmerInputFeedConversionBreeder]: 'Voerconversie (als zeugenhouderij)',
  [IntlKeys.farmerInputFeedConversionFattener]: 'Voederconversie (ongecorrigeerd)',
  [IntlKeys.farmerInputCullingPercentage]: 'Uitvalspercentage (ongecorrigeerd)',
  [IntlKeys.farmerInputGrowthPerDay]: 'Groei per dag (ongecorrigeerd)',
  [IntlKeys.farmerInputEwConversion]: 'EW-conversie (ongecorrigeerd)',
  [IntlKeys.farmerInputCompanyProductionResultsZeugenhouderij]: 'Bedrijfskengetallen Zeugenhouderij (Indien van toepassing op deze locatie)',
  [IntlKeys.farmerInputCompanyProductionResultsVleesvarkenshouderij]: 'Bedrijfskengetallen Vleesvarkenshouderij (Indien van toepassing op deze locatie)',
  [IntlKeys.farmerInputEnergyUse]: 'Energieverbruik toegekend aan de varkenshouderij op de locatie',
  [IntlKeys.farmerInputEnergyUsageHint]: 'Controleer uw afrekening om te zien of het gaat om groen of grijs gas- en elektriciteitverbruik.',
  [IntlKeys.farmerInputQuantityOfNonRenewableGasPurchased]: 'Gasverbruik niet-hernieuwbaar gas (grijs)',
  [IntlKeys.farmerInputQuantityOfRenewableGasPurchased]: 'Gasverbruik hernieuwbaar gas (groen)',
  [IntlKeys.farmerInputQuantityOfNonRenewableElectricityPurchased]: 'Elektriciteitsverbruik (grijs)',
  [IntlKeys.farmerInputQuantityOfRenewableElectricityPurchased]: 'Elektriciteitsverbruik (groen)',
  [IntlKeys.farmerInputQuantityOfTapWaterUsed]: 'Waterverbruik leidingwater',
  [IntlKeys.farmerInputWaterFromOwnSource]: 'Bronwater aanwezig',
  [IntlKeys.farmerInputSmartWaterMeterAvailable]: 'Slimme meter voor water aanwezig op bedrijf',
  [IntlKeys.farmerInputSmartGasMeterAvailable]: 'Slimme meter voor gas aanwezig op bedrijf',
  [IntlKeys.farmerInputSmartElectiricyMeterAvailable]: 'Slimme meter voor electriciteit aanwezig op bedrijf',
  [IntlKeys.farmerInputPeriodStartDate]: 'Periode start',
  [IntlKeys.farmerInputPeriodEndDate]: 'Periode eind',
  [IntlKeys.farmerInputHouseholdComposition]: 'Samenstelling huishouden voor energieverbruik',
  [IntlKeys.farmerInputDatePurchase]: 'Datum aankoop',
  [IntlKeys.farmerInputProductCode]: 'CVB Code',
  [IntlKeys.farmerInputProductName]: 'Product',
  [IntlKeys.farmerInputProductOrigin]: 'Herkomst (landcode)',
  [IntlKeys.farmerInputProductQuantity]: 'Kg product',
  [IntlKeys.farmerInputQuantityType]: 'Gewichttype (product, of droge stof)',
  [IntlKeys.farmerInputDryMatter]: 'Droge stof',
  [IntlKeys.farmerInputRe]: 'Ruw Eiwit (RE)',
  [IntlKeys.farmerInputRas]: 'Ruw As (RAS)',
  [IntlKeys.farmerInputVcre]: 'Verteringscoëfficient ruw eiwit (VRCE)',
  [IntlKeys.farmerInputVcos]: 'Verteringscoëfficient organische stof (VCOS)',
  [IntlKeys.farmerInputKg]: 'kg',
  [IntlKeys.farmerInputGramKg]: 'gram/kg',
  [IntlKeys.farmerInputProduct]: 'Product',
  [IntlKeys.farmerInputLiter]: 'liter',
  [IntlKeys.farmerInputSupplierName]: 'Producent',
  [IntlKeys.farmerInputQuantityDelivered]: 'Hoeveelheid',
  [IntlKeys.farmerInputUnit]: 'Eenheid (bijv kg)',
  [IntlKeys.farmerInputProcessedManure]: 'Verwerkte mest',
  [IntlKeys.farmerInputSodiumHydroxide]: 'Natriumhydroxide verbruikt',
  [IntlKeys.farmerInputGlycerineUsed]: 'Glycerine verbruikt',
  [IntlKeys.farmerInputDigestateProduction]: 'Digestaat productie',
  [IntlKeys.farmerInputBiogasProduction]: 'Biogas productie',
  [IntlKeys.farmerInputElectricityProduction]: 'Elektriciteit productie',
  [IntlKeys.farmerInputTonsPeriod]: 'ton/periode',
  [IntlKeys.farmerInputKgPeriod]: 'kg/periode',
  [IntlKeys.farmerInputM3Period]: 'm<sup>3</sup>/periode',
  [IntlKeys.farmerInputM2]: 'm<sup>2</sup>',
  [IntlKeys.farmerInputKwhPeriod]: 'kWh/periode',
  [IntlKeys.farmerInputGeneral]: 'Algemeen',
  [IntlKeys.farmerInputOpen]: 'Open',
  [IntlKeys.farmerInputClosed]: 'Gesloten (alle diersoorten op dit UBN)',
  [IntlKeys.farmerInputTypeOfCompany]: 'Bedrijfstype van dit UBN',
  [IntlKeys.farmerInputHouseholdCompositionInfo]: 'INFO: voor toekennen energieverbruik aan privegebruik of zakelijk gebruik',
  [IntlKeys.farmerInputTotalPriceSowsInfo]: 'INFO: de CO2-waarde wordt toegekend aan zeugen of biggen op basis van economische waarde (bijv. 100 verkochte zeugen per jaar x 100 euro per zeug = 10 000 euro/jaar)',
  [IntlKeys.farmerInputTotalPricePigletsInfo]: 'INFO: de CO2-waarde wordt toegekend aan zeugen of biggen op basis van economische waarde ( bijv. 1000 verkochte biggen per jaar x 40 euro per big = 40 000 euro/jaar)',
  [IntlKeys.farmerInputDraft]: 'Tijdelijk opslaan',
  [IntlKeys.farmerInputDraftDescription]: 'De wijzigingen worden tijdelijk opgeslagen als concept. Als u deze update als definitief wilt indienen, selecteer dan "Opslaan en verzenden" in het vorige scherm.',
  [IntlKeys.farmerInputSubmitted]: 'Opslaan en verzenden',
  [IntlKeys.farmerInputSubmittedDescription]: 'De wijzigingen wordt opgeslagen en ingediend. Als u deze gegevens als nog niet wilt indienen, selecteer dan "Terug naar concept" in het vorige scherm.',
  [IntlKeys.farmerInputRevertToDraft]: 'Terug naar concept',
  [IntlKeys.farmerInputRevertToDraftDescription]: 'De wijzigingen worden tijdelijk opgeslagen. Als u de wijzigingen definitief wilt indienen, selecteer dan "Opslaan en verzenden" in het vorige scherm.',
  [IntlKeys.farmerInputStableNumber]: 'Stal Nr',
  [IntlKeys.farmerInputRavCode]: 'RAV-code',
  [IntlKeys.farmerInputNrOfAnimals]: 'Aantal dieren',
  [IntlKeys.farmerInputSystemId]: 'Rapport-ID',
  [IntlKeys.farmerInputMainReport]: 'Main report',
  [IntlKeys.farmerInputMainReportId]: 'Hoofdrapport-ID',
  [IntlKeys.farmerInputNrPeriod]: 'aantal/periode',
  [IntlKeys.farmerInputEurPeriod]: 'euro/periode',
  [IntlKeys.farmerInputNrSowPeriod]: 'aantal/zeug/periode',
  [IntlKeys.farmerInputKgKg]: 'kg/kg',
  [IntlKeys.farmerInputGrDag]: 'gr/dag',
  [IntlKeys.farmerInputTriggeredBy]: 'Gedaan door',
  [IntlKeys.farmerInputReportType]: 'Rapporttype',
  [IntlKeys.farmerInputAction]: 'Actie',
  [IntlKeys.farmerInputActionDate]: 'Actiedatum',
  [IntlKeys.farmerInputRavCodesInfo]: 'INFO: geef voor de locatie (UBN) de Rav-code en aantal dieren (volgens BLK-norm) per code.',
  [IntlKeys.farmerInputRavCodes]: 'RAV-code',
  [IntlKeys.farmerInputRavCodesHint]: 'Let op bij het invullen van de RAV-codes. Bij de bedrijven met onderstaande diercategorieën worden de volgende RAV-codecategorieën verwacht (het is mogelijk dat deze locatie daarvan afwijkt):',
  [IntlKeys.farmerInputRavCodesHintOption1]: 'Biggenopfok (gespeende biggen): D 1.1*',
  [IntlKeys.farmerInputRavCodesHintOption2]: 'Kraamzeugen (incl. biggen tot spenen): D 1.2*',
  [IntlKeys.farmerInputRavCodesHintOption3]: 'Guste en dragende zeugen: D 1.3*',
  [IntlKeys.farmerInputRavCodesHintOption4]: 'Dekberen, 7 maanden en ouder: D 2*',
  [IntlKeys.farmerInputRavCodesHintOption5]: 'Vleesvarkens, opfokberen van ca. 25 kg tot 7 maanden, opfokzeugen van ca. 25kg tot eerste dekking: D 3*',
  [IntlKeys.farmerInputAddAtLeastOneRavCode]: 'Voeg ten minste één RAV-code',
  [IntlKeys.farmerInputCompanyDataOption]: 'Rapport {id}, {from} - {to}, {status} door {updatedBy} op {updatedAt}',
  [IntlKeys.farmerInputAverageStorageDuration]: 'Gemiddelde verblijfsduur van mest in de stalputten',
  [IntlKeys.farmerInputAdditionalManureStorage]: 'Andere vormen van mestopslag dan onder de dierverblijven',
  [IntlKeys.farmerInputShorterThanOneMonth]: 'korter dan 1 maand',
  [IntlKeys.farmerInputBetweenOneAndThreeMonth]: 'tussen de 1 en 3 maanden',
  [IntlKeys.farmerInputBetweenThreeAndFiveMonth]: 'tussen de 3 en 5 maanden',
  [IntlKeys.farmerInputBetweenFiveAndSixMonth]: 'tussen 5 en 6 maanden',
  [IntlKeys.farmerInputLongerThanSixMonth]: 'langer dan 6 maanden',
  [IntlKeys.farmerInputNone]: 'Geen',
  [IntlKeys.farmerInputManureStorageInSilo]: 'Mestopslag in silo',
  [IntlKeys.farmerInputManureStorageInBag]: 'Mestopslag in zak',
  [IntlKeys.farmerInputSolidStorageInStacksWithoutBedding]: 'Vaste mestopslag (stapelbare mest) buiten de stal met geen of nauwelijks bedding',
  [IntlKeys.farmerInputSolidStorageInStacksWithBedding]: 'Vaste mestopslag (stapelbare mest) buiten de stal met bedding (bijvoorbeeld uit een potstal of strostal)',
  [IntlKeys.farmerInputManureStorageInSiloOrBag]: 'Manure storage in silo or bag',
  [IntlKeys.farmerInputDurationOfStorage]: 'Gemiddeld duur van opslag in dit opslagtype',
  [IntlKeys.farmerInputMonthlyManureRemoval]: 'Wordt deze mest maandelijks van het bedrijf verwijderd?',
  [IntlKeys.farmerInputCallToActionWarningMessage]: 'Vergeet niet dit rapport in te dienen wanneer het klaar is',
  [IntlKeys.farmerInputReportsByProducts]: 'Bij-Producten Rapporten',
  [IntlKeys.farmerInputReportsFermentation]: 'Fermentatie Rapporten',
  [IntlKeys.farmerInputReportsManure]: 'Mestverwerking Rapporten',
  [IntlKeys.farmerInputAverageFeedConsumptionPerSowPresent]: 'Gemiddelde voerverbruik per aanwezige zeug (incl. voer opfokzeugen)',
  [IntlKeys.farmerInputPartlyClosed]: 'Gedeeltelijk gesloten (afvoer biggen en vleesvarkens)',
  [IntlKeys.farmerInputOpenSows]: 'Zeugen zonder speenbiggen',
  [IntlKeys.farmerInputOpenSowsPiglets]: 'Zeugen met speenbiggen',
  [IntlKeys.farmerInputOpenPiglets]: 'Speenbiggen opfok',
  [IntlKeys.farmerInputOpenPigletsPigs]: 'Speenbiggen en vleesvarkens',
  [IntlKeys.farmerInputOpenFinishingPigs]: 'Vleesvarkens',
  [IntlKeys.farmerInputFeedConversionKg]: 'kg/zeug',
  [IntlKeys.farmerInputElectricityUsageTooltipText]: 'Netto verbruik = verbruik – productie (uit oa zonnepanelen). Het Netto verbruik is terug te vinden op de afrekening van de energieleverancier. Dit getal kan ook negatief zijn.',
  [IntlKeys.farmerInputCollapsibleBlockTooltip]: 'INFO: geef aantal aanwezige dieren en kengetallen berekend door het Farm Management Systeem.',
  [IntlKeys.farmerInputSharedSmartMeter]: 'Bovenstaand opgegeven energieverbruik wordt gedeeld met andere bedrijfstak',
  [IntlKeys.farmerInputQuantityOfElectricityProduced]: 'Opgewekte elektriciteit voor varkenshouderij',
  [IntlKeys.farmerInputSolarPanelsSquare]: 'Aantal vierkante meter zonnepanelen op dit UBN',
  [IntlKeys.farmerInputOpenAnExampleLink]: 'Zoek en selecteer de CVB-code als deze bekend is, of gebruik een eigen grondstofcode en vul handmatig de onderstaande velden in (instructies {link}).',
  [IntlKeys.farmerInputAverageNumberSucklingPiglets]: 'Gemiddeld aantal aanwezige zogende biggen',
  [IntlKeys.farmerInputStatusAutomaticallyFound]: 'Automatisch gevonden',
  [IntlKeys.farmerInputStatusManuallyEntered]: 'Handmatig ingevoerd',
  [IntlKeys.farmerInputIsManureBeingRemoved]: 'Wordt er mest afgevoerd naar een mestvergister?',
  [IntlKeys.farmerInputManureRemovingFrequency]: 'Hoe vaak wordt de mest afgevoerd naar de vergister?',
  [IntlKeys.farmerInputByProductsOrLoose]: 'Worden er op deze locatie bijproducten of losse grondstoffen gevoerd?',
  [IntlKeys.farmerInputFeedFermentation]: 'Vindt er op deze locatie fermentatie van voeders plaats?',
  [IntlKeys.farmerInputSolarPanelsSquare]: 'Aantal vierkante meter zonnepanelen op dit UBN',
  [IntlKeys.daily]: 'Dagelijks',
  [IntlKeys.weekly]: 'Wekelijks',
  [IntlKeys.monthly]: 'Maandelijks',
  [IntlKeys.onceEvery1to3Months]: 'Iedere 1 tot 3 maanden',
  [IntlKeys.onceEvery3to5Months]: 'Iedere 3 tot 5 maanden',
  [IntlKeys.lessThanOnceEvery6Months]: 'Minder dan 1x per 6 maanden',
  [IntlKeys.farmerInputManureDigestion]: 'Vindt de mestvergisting plaats op dit UBN?',
  [IntlKeys.farmerInputFeedSuppliers]: 'Voerleveranciers',
  [IntlKeys.farmerInputFeedSuppliersHint]: 'Zoek en selecteer de naam van de leverancier(s) of vul handmatig een naam in als deze niet beschikbaar is in de lijst (ten minste één van de drie velden moet worden ingevuld).',
  [IntlKeys.farmerInputFeedSuppliersPiglets]: 'Welke voerleverancier(s) heeft/hebben in deze periode voer(grondstoffen) geleverd voor de diercategorie biggen?',
  [IntlKeys.farmerInputFeedSuppliersSows]: 'Welke voerleverancier(s) heeft/hebben in deze periode voer(grondstoffen) geleverd voor de diercategorie zeugen?',
  [IntlKeys.farmerInputFeedSuppliersFatteningPigs]: 'Welke voerleverancier(s) heeft/hebben in deze periode voer(grondstoffen) geleverd voor de diercategorie vleesvarkens?',
  [IntlKeys.farmerInputFeedSuppliersHighlighted]: 'Welke voerleverancier(s) heeft/hebben in deze periode voer(grondstoffen) geleverd voor de diercategorie {category}?',
  [IntlKeys.farmerInputFeedSuppliersFatteningPigsCategory]: 'vleesvarkens',
  [IntlKeys.farmerInputFeedSuppliersSowsCategory]: 'zeugen',
  [IntlKeys.farmerInputFeedSuppliersPigletsCategory]: 'biggen',
  [IntlKeys.farmerInputAreRavCodesCorrect]: 'Kloppen uw RAV codes? U heeft RAV codes opgegeven die gemiddeld gezien niet overeenkomen met uw bedrijfstype.',
  [IntlKeys.farmerInputRavCodesCorrect]: 'Ik heb ze gecontroleerd en de RAV codes kloppen',
  [IntlKeys.farmerInputRavCodesIncorrect]: 'Terug',
  [IntlKeys.farmerInputCompanyTypeWarn]: 'Is uw bedrijfstype correct? De vorige keer heeft u velden ingevuld die niet overeenkomen met uw bedrijfstype.',
  [IntlKeys.farmerInputAtLeastOneFeedSupplierIsRequired]: 'Ten minste één van deze velden moet worden ingevuld.',
  [IntlKeys.farmerInputNotAvailableDutToTheCompanyType]: 'Niet beschikbaar vanwege het geselecteerde bedrijfstype.',
  [IntlKeys.farmerInputIncorrectValueRange]: 'De opgegeven waarde wijkt af van de verwachtte waarden. Controleer of de waarde daadwerkelijk klopt.',
  [IntlKeys.farmerInputProductFedToAnimalCategory]: 'Product gevoerd aan diercategorie',
  [IntlKeys.farmerInputPigletsPercent]: 'Biggen: %',
  [IntlKeys.farmerInputSowsPercent]: 'Zeugen: %',
  [IntlKeys.farmerInputPigsPercent]: 'Vleesvarkens: %',
  [IntlKeys.farmerInputSumOfPercentsShouldBe100]: 'De som van de percentages moet 100 zijn',

  [IntlKeys.farmerInputManureTransportations]: 'Vervoer Dierlijke Mest',
  [IntlKeys.farmerInputManureInstructionFirstRow]: 'Upload hier uw Transportbericht voor Vervoer Dierlijke Mest van',
  [IntlKeys.farmerInputManureInstructionSecondRow]: 'RVO, voor de geselecteerde tijdsperiode en UBN (instructies ',
  [IntlKeys.farmerInputManureHere]: 'hier',
  // reference tables

  [IntlKeys.referenceTablesId]: 'ID',
  [IntlKeys.referenceTablesStatus]: 'Status',
  [IntlKeys.referenceTablesCategory]: 'Category',
  [IntlKeys.referenceTablesFilename]: 'Filename',
  [IntlKeys.referenceTablesValidDateFrom]: 'Valid date from',
  [IntlKeys.referenceTablesValidDateTo]: 'Valid date to',
  [IntlKeys.referenceTablesNotes]: 'Notities',
  [IntlKeys.referenceTablesValidDate]: 'Geldige datum',
  [IntlKeys.referenceTablesUploadDate]: 'Upload datum',
  [IntlKeys.referenceTablesAsPensLevel]: 'AS_PensLevel',
  [IntlKeys.referenceTablesPensLevel]: 'Pens Level',
  [IntlKeys.referenceTablesPensName]: 'Pens Name',
  [IntlKeys.referenceTablesPensNameEN]: 'Pens Name EN',
  [IntlKeys.referenceTablesActive]: 'Actief',
  [IntlKeys.referenceTablesInputText]: 'Input text',
  [IntlKeys.referenceTablesFrom]: 'Van',
  [IntlKeys.referenceTablesTo]: 'Naar',
  [IntlKeys.referenceTablesUploadedInfo]: 'Geupload door {username} op {date}',
  [IntlKeys.referenceTablesAsPensTypeSowBarn]: 'AS_PensTypeSowBarn',
  [IntlKeys.referenceTablesPensSowBarnCode]: 'Pens type sow barn code',
  [IntlKeys.referenceTablesPensSowBarnName]: 'Pens type sow barn name',
  [IntlKeys.referenceTablesAsYesNo]: 'AS_YesNo',
  [IntlKeys.referenceTablesYesNoCode]: 'Yes No Code',
  [IntlKeys.referenceTablesYesNoName]: 'Yes No Name',
  [IntlKeys.referenceTablesInactive]: 'Inactief',
  [IntlKeys.referenceTablesCfpCo2EmissionFactors]: 'CFP_CO2EmissionFactors',
  [IntlKeys.referenceTablesCfpCo2EmissionFactorsSource]: 'energy or water source',
  [IntlKeys.referenceTablesCfpCo2EmissionFactorsVolume]: 'kg CO2/eenheid',
  [IntlKeys.referenceTablesCfpCo2EmissionFactorsReference]: 'Reference',
  [IntlKeys.referenceTablesCvbTable]: 'CFP_CVB_Table',
  [IntlKeys.referenceTablesProductCode]: 'product code',
  [IntlKeys.referenceTablesProductSubCode]: 'product_subcode',
  [IntlKeys.referenceTablesProductType]: 'soort_product',
  [IntlKeys.referenceTablesProductName]: 'productnaam',
  [IntlKeys.referenceTablesClassName]: 'klassenaam',
  [IntlKeys.referenceTablesSubClassName]: 'subklassenaam',
  [IntlKeys.referenceTablesProductNameEN]: 'productName',
  [IntlKeys.referenceTablesClassNameEN]: 'className',
  [IntlKeys.referenceTablesSubClassNameEN]: 'subClassName',
  [IntlKeys.referenceTablesDs]: 'DS',
  [IntlKeys.referenceTablesDsUnit]: 'DS Unit',
  [IntlKeys.referenceTablesRas]: 'RAS',
  [IntlKeys.referenceTablesRasUnit]: 'RAS Unit',
  [IntlKeys.referenceTablesRein]: 'REin',
  [IntlKeys.referenceTablesReinUnit]: 'REin Unit',
  [IntlKeys.referenceTablesRe]: 'RE',
  [IntlKeys.referenceTablesReUnit]: 'RE Unit',
  [IntlKeys.referenceTablesCvcRe]: 'C_VC_RE',
  [IntlKeys.referenceTablesCvcReUnit]: 'C_VC_RE Unit',
  [IntlKeys.referenceTablesCvcOs]: 'C_VC_OS',
  [IntlKeys.referenceTablesCvcOsUnit]: 'C_VC_OS Unit',
  [IntlKeys.referenceTablesIpccEmissionFactors]: 'CFP_IPCC_Emission_Factors_Indir',
  [IntlKeys.referenceTablesFactor]: 'Factor',
  [IntlKeys.referenceTablesDescription]: 'Description',
  [IntlKeys.referenceTablesDefaultValue]: 'Default value',
  [IntlKeys.referenceTablesUncertaintyRange]: 'Uncertainty range',
  [IntlKeys.referenceTablesCfpGfliFeedprintTable]: 'CFP_GFLI_Feedprint_table',
  [IntlKeys.referenceTablesCfpGfliFeedprintTableFeedPrintCode]: 'Code FeedprintCVB',
  [IntlKeys.referenceTablesCfpGfliFeedprintTableCvbCode]: 'CVB code',
  [IntlKeys.referenceTablesCfpGfliFeedprintTableSource]: 'Source',
  [IntlKeys.referenceTablesCfpGfliFeedprintTableProductNameEn]: 'Product Name EN',
  [IntlKeys.referenceTablesCfpGfliFeedprintTableProductNameNl]: 'Product Name NL',
  [IntlKeys.referenceTablesCfpGfliFeedprintTableProductCodeEu]: 'EU product code',
  [IntlKeys.referenceTablesCfpGfliFeedprintTableProductNameEu]: 'EU product name',
  [IntlKeys.referenceTablesCfpGfliFeedprintTableOrigin]: 'Origin',
  [IntlKeys.referenceTablesCfpGfliFeedprintTableClimate]: 'Climate (incl. luc) (g CO2 eq/kg)',
  [IntlKeys.referenceTablesContentOfNitricOxide]: 'CFP_NEMA_Emission_factors_for_other_gaseous_N-losses',
  [IntlKeys.referenceTablesContentOfNitricOxideCategory]: 'Livestock category',
  [IntlKeys.referenceTablesContentOfNitricOxideType]: 'Manure_type',
  [IntlKeys.referenceTablesContentOfNitricOxideValueN2oNo]: 'N2O and NO',
  [IntlKeys.referenceTablesContentOfNitricOxideUnitN2oNo]: 'N2O and NO Unit',
  [IntlKeys.referenceTablesContentOfNitricOxideValueN2]: 'N2',
  [IntlKeys.referenceTablesContentOfNitricOxideUnitN2]: 'N2 Unit',
  [IntlKeys.referenceTablesPigHousingEmissionFactors]: 'CFP_NEMA_NH3-emission_factors_for_pig_housing',
  [IntlKeys.referenceTablesPigHousingEmissionFactorsCategory]: 'Livestock_category',
  [IntlKeys.referenceTablesPigHousingEmissionFactorsSystem]: 'Housing_system',
  [IntlKeys.referenceTablesPigHousingEmissionFactorsYearValue]: 'Year_of_Value',
  [IntlKeys.referenceTablesPigHousingEmissionFactorsEmissionValue]: 'Value',
  [IntlKeys.referenceTablesPigHousingEmissionFactorsUnit]: 'Value Unit',
  [IntlKeys.referenceTablesElectricityConsumptionRates]: 'CFP_NIBUD_electriciteitsverbruik',
  [IntlKeys.referenceTablesElectricityConsumptionRatesPeopleQuantity]: 'nrPeopleHousehold',
  [IntlKeys.referenceTablesElectricityConsumptionRatesConsumption]: 'electricityConsumption',
  [IntlKeys.referenceTablesGasConsumptionRates]: 'CFP_NIBUD_gasverbruik',
  [IntlKeys.referenceTablesGasConsumptionRatesHouseType]: 'houseType',
  [IntlKeys.referenceTablesGasConsumptionRatesGasConsumption]: 'gasConsumption',
  [IntlKeys.referenceTablesWaterConsumptionRates]: 'CFP_NIBUD_waterverbruik',
  [IntlKeys.referenceTablesWaterConsumptionRatesHousehold]: 'nrPeopleHousehold',
  [IntlKeys.referenceTablesWaterConsumptionRatesConsumption]: 'waterConsumption',
  [IntlKeys.referenceTablesRavCodes]: 'CFP_RAV_codes',
  [IntlKeys.referenceTablesRavCodesAnimalCategory]: 'Diercategorie',
  [IntlKeys.referenceTablesRavCodesRavCode]: 'RAVCode',
  [IntlKeys.referenceTablesRavCodesAnimalDescriptionNl]: 'DierOmschrijving',
  [IntlKeys.referenceTablesRavCodesAnimalDescriptionEn]: 'AnimalDescription',
  [IntlKeys.referenceTablesRavCodesAnimalEnclosureCategory]: 'DierverblijfCategorie',
  [IntlKeys.referenceTablesRavCodesAmmonia]: 'Ammoniak',
  [IntlKeys.referenceTablesRavCodesAmmoniaUnit]: 'AmmoniakUnit',
  [IntlKeys.referenceTablesGwp100Factors]: 'CFP_WUR_GWP100factor',
  [IntlKeys.referenceTablesGwp100FactorsGreenhouseGas]: 'Greenhouse gas',
  [IntlKeys.referenceTablesGwp100FactorsGwp]: 'GWP100 factor',
  [IntlKeys.referenceTablesGwp100FactorsGwpUnit]: 'GWP100 factor Unit',
  [IntlKeys.referenceTablesWurMfcEfem]: 'CFP_WUR_MCF',
  [IntlKeys.referenceTablesWurMfcEfemSystem]: 'System',
  [IntlKeys.referenceTablesWurMfcEfemDurationDung]: 'Average storage duration of manure',
  [IntlKeys.referenceTablesWurMfcEfemMfc]: 'MCF',
  [IntlKeys.referenceTablesCl550]: 'cl550',
  [IntlKeys.referenceTablesCl550MainGroupCode]: 'Code Hoofdgroep',
  [IntlKeys.referenceTablesCl550SubGroupCode]: 'Code Subgroep',
  [IntlKeys.referenceTablesCl550CodeDetails]: 'Code Detallering',
  [IntlKeys.referenceTablesCl550MainGroupName]: 'Hoofdgroep',
  [IntlKeys.referenceTablesCl550SubgroupName]: 'Subgroep',
  [IntlKeys.referenceTablesCl550Detailing]: 'Detaillering',
  [IntlKeys.referenceTablesCl550English]: 'ENGLISH',
  [IntlKeys.referenceTablesCl550German]: 'DEUTSCH',
  [IntlKeys.referenceTablesCl550CodeCondition]: 'Code Aandoening',
  [IntlKeys.referenceTablesCl550Edi]: 'EDI',
  [IntlKeys.referenceTablesCl550Dgr]: 'DGR',
  [IntlKeys.referenceTablesCl550DiseaseAndTreatment]: 'ziekte/behandeling',
  [IntlKeys.referenceTablesCl550Failure]: 'uitval',
  [IntlKeys.referenceTablesCl550Disposal]: 'afvoer',
  [IntlKeys.referenceTablesCl550Selection]: 'selectie',
  [IntlKeys.referenceTablesCl550LabResult]: 'lab.uitslag',
  [IntlKeys.referenceTablesCl550Section]: 'sectie',
  [IntlKeys.referenceTablesCl550SlaughterhouseData]: 'slachthuisgegevens',
  [IntlKeys.referenceTablesCl550Agility]: 'berigheid',
  [IntlKeys.referenceTablesCl550Coverage]: 'dekking',
  [IntlKeys.referenceTablesCl550Birth]: 'Geboorte',
  [IntlKeys.referenceTablesCl550SuckingPiglet]: 'zuigende big',
  [IntlKeys.referenceTablesCl550WeanedBig]: 'gespeende big',
  [IntlKeys.referenceTablesCl550RearingSowAndBoar]: 'opfokzeug/beer',
  [IntlKeys.referenceTablesCl550GoodSow]: 'guste zeug',
  [IntlKeys.referenceTablesCl550GestationSow]: 'dragende zeug',
  [IntlKeys.referenceTablesCl550LactatingSow]: 'lacterende zeug',
  [IntlKeys.referenceTablesCl550Beer]: 'beer',
  [IntlKeys.referenceTablesCl550Finisher]: 'vleesvarken',
  [IntlKeys.referenceTablesCl550Comment]: 'opmerkingen',
  [IntlKeys.referenceTablesCl550Added]: 'toegevoegd',
  [IntlKeys.referenceTablesCl550Deleted]: 'verwijderd',
  [IntlKeys.referenceTablesCl649]: 'cl649',
  [IntlKeys.referenceTablesCl649Code]: 'code',
  [IntlKeys.referenceTablesCl649Description]: 'description',
  [IntlKeys.referenceTablesCl649Remarks]: 'notes',
  [IntlKeys.referenceTablesCl650]: 'cl650',
  [IntlKeys.referenceTablesCl650Code]: 'code',
  [IntlKeys.referenceTablesCl650Description]: 'description',
  [IntlKeys.referenceTablesCl650Note]: 'notes',
  [IntlKeys.referenceTablesCl607]: 'cl607',
  [IntlKeys.referenceTablesCl607Code]: 'code',
  [IntlKeys.referenceTablesCl607Description]: 'description',
  [IntlKeys.referenceTablesCl607Remarks]: 'notes',
  [IntlKeys.referenceTablesSuppliersNumbers]: 'cl420',
  [IntlKeys.referenceTablesSuppliersNumbersCodeList]: 'Code List',
  [IntlKeys.referenceTablesSuppliersNumbersSupplierNumber]: 'Supplier Code',
  [IntlKeys.referenceTablesSuppliersNumbersFeedSupplierName]: 'Supplier Name',
  [IntlKeys.referenceTablesSuppliersNumbersEntryDate]: 'Date From',
  [IntlKeys.referenceTablesSuppliersNumbersDischargeDate]: 'Date To',
  [IntlKeys.referenceTablesSuppliersNumbersRemark]: 'Remarks',
  [IntlKeys.referenceTablesCountryCodes]: 'ISO 3166 Country Codes',
  [IntlKeys.referenceTablesCountryCodesIso31661Code]: 'ISO 3166-1 Code',
  [IntlKeys.referenceTablesCountryCodesSubdivisionName]: 'Subdivision Name',
  [IntlKeys.referenceTablesCountryCodesIso31662Code]: 'ISO 3166-2 Code',
  [IntlKeys.referenceTablesCountryCodesCountryName]: 'Country Name',
  [IntlKeys.referenceTablesStatusAlertTitle]: 'Bevestig alstublieft dat de status van deze tabel "Actief" wordt!',
  [IntlKeys.referenceTablesStatusDescription]: 'Als geldige datum andere tabellen van dit type kruist, dan worden geldige datums van andere tabellen gewijzigd',
  [IntlKeys.referenceTablesActiveTableExists]: 'Er zijn actieve versies met overlappende datums. Een actieve bestaande versie van de referentie tabel zal een start- en/ of vervaldatum toegewezen krijgen, zodat er geen overlap in datums is.',
  [IntlKeys.referenceTablesRavReferenceList]: 'RAV_Referentielijst',
  [IntlKeys.referenceTablesRavReferenceListAnimalCategory]: 'Animal category',
  [IntlKeys.referenceTablesRavReferenceListDescription]: 'Description',
  [IntlKeys.referenceTablesRavReferenceListEfem]: 'EF_em',
  [IntlKeys.referenceTablesRavReferenceListHousingType]: 'Housing type',
  [IntlKeys.referenceTablesAllocationManure]: 'Allocation Manure',
  [IntlKeys.referenceTablesAllocationManureAnimalCategory]: 'Animal Category',
  [IntlKeys.referenceTablesAllocationManureManure]: 'Manure',
  [IntlKeys.referenceTablesAllocationManureGas]: 'Gas',
  [IntlKeys.referenceTablesAllocationManureElectricity]: 'Electricity',
  [IntlKeys.referenceTablesAllocationManureWater]: 'Water',
  [IntlKeys.referenceTablesGreenhouseGasEmission]: 'Greenhouse gas emissions',
  [IntlKeys.referenceTablesGreenhouseGasEmissionCountry]: 'Country',
  [IntlKeys.referenceTablesGreenhouseGasEmissionLocation]: 'Location',
  [IntlKeys.referenceTablesGreenhouseGasEmissionCategory]: 'Category',
  [IntlKeys.referenceTablesGreenhouseGasEmissionAccount]: 'Account',
  [IntlKeys.referenceTablesGreenhouseGasEmissionName]: 'Name',
  [IntlKeys.referenceTablesGreenhouseGasEmissionEmissionFactor]: 'Emission factors (kg CO2-eq)',
  [IntlKeys.referenceTablesGreenhouseGasEmissionUnit]: 'Unit (ton CO2-eq)',
  [IntlKeys.referenceTablesGreenhouseGasEmissionConversionFactor]: 'Conversion Factor',
  [IntlKeys.referenceTablesGreenhouseGasEmissionFormulaCf]: 'formule CF',
  [IntlKeys.referenceTablesGreenhouseGasEmissionExtraExternalId]: 'Custom1',
  [IntlKeys.referenceTablesGreenhouseGasEmissionInputUnit]: 'Input unit',
  [IntlKeys.referenceTablesGreenhouseGasEmissionConversionFormula]: 'Conversion formula',
  [IntlKeys.referenceTablesGreenhouseGasEmissionEmissionFactors]: 'Emission factors (kg CO2-eq. of kg CH4 per eenheid)',
  [IntlKeys.referenceTablesGreenhouseGasEmissionCo2Unit]: 'Unit (kg CO2-eq)',

  // entities list
  [IntlKeys.referenceTablesEntitiesList]: 'Entities list',
  [IntlKeys.referenceTablesEntitiesListEntityId]: 'Entity ID',
  [IntlKeys.referenceTablesEntitiesListEntityName]: 'Entity name',
  [IntlKeys.referenceTablesEntitiesListCountry]: 'Country',
  [IntlKeys.referenceTablesEntitiesListBusinessUnit]: 'Business Unit',

  // farm management data

  [IntlKeys.farmManagementDataCompanyId]: 'Company ID',
  [IntlKeys.farmManagementDataTimestamp]: 'Timestamp',
  [IntlKeys.farmManagementDataGroupGroups]: 'G_Groups',
  [IntlKeys.farmManagementDataGroupsList]: 'Groups List',
  [IntlKeys.farmManagementDataFarmId]: 'Farm ID',
  [IntlKeys.farmManagementDataGroupId]: 'Group ID',
  [IntlKeys.farmManagementDataGroupName]: 'Group name',
  [IntlKeys.farmManagementDataDateEnd]: 'Date end',
  [IntlKeys.farmManagementDataNotes]: 'Notes',
  [IntlKeys.farmManagementDataBarnId]: 'Barn ID',
  [IntlKeys.farmManagementDataRoomId]: 'Room ID',
  [IntlKeys.farmManagementDataPenId]: 'Pen ID',
  [IntlKeys.farmManagementDataGroupDatetime]: 'Group datetime',
  [IntlKeys.farmManagementDataFirstDeliveryGrowthExp]: 'First delivery growth exp',
  [IntlKeys.farmManagementDataLastDeliveryGrowthExp]: 'Last delivery growth exp',
  [IntlKeys.farmManagementDataFirstDeliveryPer]: 'First delivery perc',
  [IntlKeys.farmManagementDataLastDeliveryPerc]: 'Last delivery perc',
  [IntlKeys.farmManagementDataFirstDeliveryWeight]: 'First delivery weight',
  [IntlKeys.farmManagementDataLastDeliveryWeight]: 'Last delivery weight',
  [IntlKeys.farmManagementDataAbout]: 'About farm management',
  [IntlKeys.farmManagementDataId]: 'System ID',
  [IntlKeys.farmManagementDataUploadType]: 'Category',
  [IntlKeys.farmManagementDataFileName]: 'File',
  [IntlKeys.farmManagementDataSource]: 'Source',
  [IntlKeys.farmManagementDataCategory]: 'Category',
  [IntlKeys.farmManagementDataCreatedAt]: 'Created At',
  [IntlKeys.farmManagementDataGroupFinancialEvents]: 'G_Financial Events',
  [IntlKeys.farmManagementDataFinEventId]: 'Fin event ID',
  [IntlKeys.farmManagementDataEventTypeId]: 'Event type ID',
  [IntlKeys.farmManagementDataEventDate]: 'Event date',
  [IntlKeys.farmManagementDataContactId]: 'Contact ID',
  [IntlKeys.farmManagementDataAmountExVat]: 'Amount ex vat',
  [IntlKeys.farmManagementDataAmountVat]: 'Amount vat',
  [IntlKeys.farmManagementDataAmountInVat]: 'Amount in vat',
  [IntlKeys.farmManagementDataInvoiceNumber]: 'Invoice number',
  [IntlKeys.farmManagementDataInvoceDate]: 'Invoce date',
  [IntlKeys.farmManagementDataFinancialNotes]: 'Financial notes',
  [IntlKeys.farmManagementDataValve]: 'Valve',
  [IntlKeys.farmManagementDataInputEventId]: 'Input event ID',
  [IntlKeys.farmManagementDataImportId]: 'Import ID',
  [IntlKeys.farmManagementDataInputWarnings]: 'Input warnings',
  [IntlKeys.farmManagementDataInputTypeStamp]: 'Input type stamp',
  [IntlKeys.farmManagementDataScannedIds]: 'Scanned IDs',
  [IntlKeys.farmManagementDataUserId]: 'User ID',
  [IntlKeys.farmManagementDataBatchNumberProduction]: 'Batch number production',
  [IntlKeys.farmManagementDataBatchNumberDelivery]: 'Batch number delivery',
  [IntlKeys.farmManagementDataGroupWeights]: 'G_GROUP WEIGHT',
  [IntlKeys.farmManagementDataWeightsList]: 'Weights List',
  [IntlKeys.farmManagementDataNumber]: 'Number',
  [IntlKeys.farmManagementDataTotalWeight]: 'Total weight',
  [IntlKeys.farmManagementDataGroupRemovals]: 'G_GROUP REMOVAL',
  [IntlKeys.farmManagementDataRemovalList]: 'Removal List',
  [IntlKeys.farmManagementDataAlive]: 'Alive',
  [IntlKeys.farmManagementDataWeightSlaughtered]: 'Weight Slaughtered',
  [IntlKeys.farmManagementDataWeightAlive]: 'Weight Alive',
  [IntlKeys.farmManagementDataFirstReamson]: 'Rem Reason 1',
  [IntlKeys.farmManagementDataSecondReamson]: 'Rem Reason 2',
  [IntlKeys.farmManagementDataRemtypeId]: 'Rem Type ID',
  [IntlKeys.farmManagementDataAnimalCategoryId]: 'Animal category ID',
  [IntlKeys.farmManagementDataWcfId]: 'WCF ID',
  [IntlKeys.farmManagementDataNotClassified]: 'Not Classified',
  [IntlKeys.farmManagementDataClassType]: 'Class Type',
  [IntlKeys.farmManagementDataNlMeatPerc]: 'NL Meat Perc',
  [IntlKeys.farmManagementDataNlAa]: 'NL AA',
  [IntlKeys.farmManagementDataNlA]: 'NL A',
  [IntlKeys.farmManagementDataNlB]: 'NL B',
  [IntlKeys.farmManagementDataNlC]: 'NL C',
  [IntlKeys.farmManagementDataNlBackFat]: 'NL Back Fat',
  [IntlKeys.farmManagementDataNlLoInDepth]: 'NL Lo In Depth',
  [IntlKeys.farmManagementDataDeLachs]: 'DE Lachs',
  [IntlKeys.farmManagementDataDeSchinken]: 'DE Schinken',
  [IntlKeys.farmManagementDataDeSchaulter]: 'DE Schaulter',
  [IntlKeys.farmManagementDataDeIndexPunkte]: 'DE Index Punkte',
  [IntlKeys.farmManagementDataDeIndexFactor]: 'DE Index Factor',
  [IntlKeys.farmManagementDataEstimatedDateIn]: 'Estimated Date In',
  [IntlKeys.farmManagementDataSexId]: 'Sex ID',
  [IntlKeys.farmManagementDataWithoutMale]: 'No Male',
  [IntlKeys.farmManagementDataWithoutFemale]: 'No Female',
  [IntlKeys.farmManagementDataWithoutMaleCastrated]: 'No Male Castrated',
  [IntlKeys.farmManagementDataCarPleuritis]: 'Car Pleuritis',
  [IntlKeys.farmManagementDataCarInfSkin]: 'Car Inf Skin',
  [IntlKeys.farmManagementDataCarInfFeed]: 'Car Inf Feed',
  [IntlKeys.farmManagementDataCarPleuritisInfFeed]: 'Car Pleuritis Inf Feed',
  [IntlKeys.farmManagementDataCarInfSkinAndFeed]: 'Car Inf Skin And Feed',
  [IntlKeys.farmManagementDataCarPleuritisInfSkin]: 'Car Pleuritis Inf Skin',
  [IntlKeys.farmManagementDataCarPleuritisInfSkinAndFeed]: 'Car Pleuritis Inf Skin And Feed',
  [IntlKeys.farmManagementDataOrganAffLiver]: 'Organ Aff Liver',
  [IntlKeys.farmManagementDataOrganRejLiver]: 'Organ Rej Liver',
  [IntlKeys.farmManagementDataOrganAffLungs]: 'Organ Aff Lungs',
  [IntlKeys.farmManagementDataOrganAffLungsAndLiver]: 'Organ Aff Lungs And Liver',
  [IntlKeys.farmManagementDataOrganAffLungsRejLiver]: 'Organ Aff Lungs Rej Liver',
  [IntlKeys.farmManagementDataQualId]: 'Qual ID',
  [IntlKeys.farmManagementDataGroupsArrival]: 'G_Groups Arrival',
  [IntlKeys.farmManagementDataArrivalList]: 'Groups Arrival List',
  [IntlKeys.farmManagementDataFinancialEventId]: 'Financial event ID',
  [IntlKeys.farmManagementDataGroupArrivalNumber]: 'Group arrival number',
  [IntlKeys.farmManagementDataOriginId]: 'Origin ID',
  [IntlKeys.farmManagementDataGroupArrivalDatetime]: 'Group arrival datetime',
  [IntlKeys.farmManagementDataOwnFarm]: 'Own farm',
  [IntlKeys.farmManagementDataGroupMoves]: 'G_GROUP MOVE',
  [IntlKeys.farmManagementDataGroupFrom]: 'Group From',
  [IntlKeys.farmManagementDataBarnFrom]: 'Barn From',
  [IntlKeys.farmManagementDataRoomFrom]: 'Room From',
  [IntlKeys.farmManagementDataPenFrom]: 'Pen From',
  [IntlKeys.farmManagementDataAnimalCatIdFrom]: 'Animal Cat Id From',
  [IntlKeys.farmManagementDataWeight]: 'Weight',
  [IntlKeys.farmManagementDataAnimalCatIdTo]: 'Animal Cat Id To',
  [IntlKeys.farmManagementDataAdminDate]: 'Admin Date',
  [IntlKeys.farmManagementDataRemReason]: 'Rem Reason',
  [IntlKeys.farmManagementDataCodesAnimalRemarks]: 'C_Animal Remark',
  [IntlKeys.farmManagementDataAnimalRemarksList]: 'Animal Remarks List',
  [IntlKeys.farmManagementDataAnimalRemarkId]: 'Animal Rem ID',
  [IntlKeys.farmManagementDataAbbreviation]: 'Abbreviation',
  [IntlKeys.farmManagementDataDescription]: 'Description',
  [IntlKeys.farmManagementDataNote]: 'Note',
  [IntlKeys.farmManagementDataCode]: 'EDI Code',
  [IntlKeys.farmManagementDataCodesDefects]: 'C_Defects',
  [IntlKeys.farmManagementDataDefectsList]: 'Defects List',
  [IntlKeys.farmManagementDataDefectId]: 'Defect ID',
  [IntlKeys.farmManagementDataWorkList]: 'Work List',
  [IntlKeys.farmManagementDataCodesDiagnose]: 'C_Diagnose',
  [IntlKeys.farmManagementDataDiagnosesList]: 'Diagnoses List',
  [IntlKeys.farmManagementDataDiagnoseId]: 'Diagnose ID',
  [IntlKeys.farmManagementDataCodesAnimalScore]: 'C_Animal Score',
  [IntlKeys.farmManagementDataAnimalScoreList]: 'Animal Scores List',
  [IntlKeys.farmManagementDataAnimalScoreId]: 'Animal score ID',
  [IntlKeys.farmManagementDataCodeGroup]: 'Code group',
  [IntlKeys.farmManagementDataScoreEnd]: 'Score end',
  [IntlKeys.farmManagementDataSows]: 'Sows',
  [IntlKeys.farmManagementDataBoars]: 'Boars',
  [IntlKeys.farmManagementDataWeaners]: 'Weaners',
  [IntlKeys.farmManagementDataSucklers]: 'Sucklers',
  [IntlKeys.farmManagementDataReplstock]: 'Replstock',
  [IntlKeys.farmManagementDataFinishers]: 'Finishers',
  [IntlKeys.farmManagementDataCodesInsemination]: 'C_Insemination',
  [IntlKeys.farmManagementDataInseminationList]: 'Insemination List',
  [IntlKeys.farmManagementDataInseminationId]: 'Insemination ID',
  [IntlKeys.farmManagementDataCodesTreatments]: 'C_Treatment',
  [IntlKeys.farmManagementDataTreatmentsList]: 'Treatments List',
  [IntlKeys.farmManagementDataTreatmentId]: 'Treatment ID',
  [IntlKeys.farmManagementDataRegistrationNumber]: 'Registration Number',
  [IntlKeys.farmManagementDataWhPeriod]: 'WH Period',
  [IntlKeys.farmManagementDataDestination]: 'Destination',
  [IntlKeys.farmManagementDataTreatmentsPerDay]: 'Treatment Per Day',
  [IntlKeys.farmManagementDataFirstRepeatTreatment]: 'First Repeat Treatment',
  [IntlKeys.farmManagementDataSecondRepeatTreatment]: 'Second Repeat Treatment',
  [IntlKeys.farmManagementDataThirdRepeatTreatment]: 'Third Repeat Treatment',
  [IntlKeys.farmManagementDataFourthRepeatTreatment]: 'Fourth Repeat Treatment',
  [IntlKeys.farmManagementDataFifthRepeatTreatment]: 'Fifth Repeat Treatment',
  [IntlKeys.farmManagementDataGroupPurchasedFood]: 'G_Food',
  [IntlKeys.farmManagementDataPurchasedFoodList]: 'Food List',
  [IntlKeys.farmManagementDataGilts]: 'Gilts',
  [IntlKeys.farmManagementDataEv]: 'EV',
  [IntlKeys.farmManagementDataDm]: 'DM',
  [IntlKeys.farmManagementDataAcctId]: 'Acct Identifier',
  [IntlKeys.farmManagementDataGroupUsedFood]: 'G_Food Used',
  [IntlKeys.farmManagementDataUsedFoodList]: 'Used Food List',
  [IntlKeys.farmManagementDataCodesMatingType]: 'C_Mating Type',
  [IntlKeys.farmManagementDataMatingTypeList]: 'Mating Types List',
  [IntlKeys.farmManagementDataMatingTypeId]: 'Mating Type ID',
  [IntlKeys.farmManagementDataCodesWeightType]: 'C_Weight Type',
  [IntlKeys.farmManagementDataWeightTypeList]: 'Weight Types List',
  [IntlKeys.farmManagementDataWeightTypeId]: 'Weight Type ID',
  [IntlKeys.farmManagementDataCodesRemovalType]: 'C_Removal Type',
  [IntlKeys.farmManagementDataRemovalTypeList]: 'Removal Types List',
  [IntlKeys.farmManagementDataRemovalTypeId]: 'Removal Type ID',
  [IntlKeys.farmManagementDataCodesAnimalIdentityType]: 'C_Animal Identity Type',
  [IntlKeys.farmManagementDataAnimalIdentityTypeList]: 'Animal Identity Types List',
  [IntlKeys.farmManagementDataIdForTypeIdentifier]: 'Identity Type ID',
  [IntlKeys.farmManagementDataUniqueIdentifier]: 'Unique ID',
  [IntlKeys.farmManagementDataTransponder]: 'Transponder',
  [IntlKeys.farmManagementDataCodesFarmOption]: 'C_Farm Option',
  [IntlKeys.farmManagementDataFarmOptionList]: 'Farm Options List',
  [IntlKeys.farmManagementDataFarmOptionId]: 'Farm Option ID',
  [IntlKeys.farmManagementDataValue]: 'Value',
  [IntlKeys.farmManagementDataCodesBarn]: 'C_Barn',
  [IntlKeys.farmManagementDataBarnList]: 'Barns List',
  [IntlKeys.farmManagementDataCorrectionFin]: 'Correction Fin',
  [IntlKeys.farmManagementDataIsEarlyWeanUnit]: 'Is Early Wean Unit',
  [IntlKeys.farmManagementDataSurface]: 'Surface',
  [IntlKeys.farmManagementDataVvoNumber]: 'VVO Number',
  [IntlKeys.farmManagementDataEuApprovalNumber]: 'EU Approval Number',
  [IntlKeys.farmManagementDataCorrectionWeaners]: 'Correction Weaners',
  [IntlKeys.farmManagementDataCorrectionReplGilts]: 'Correction Repl Gilts',
  [IntlKeys.farmManagementDataCodesRoom]: 'C_Room',
  [IntlKeys.farmManagementDataRoomList]: 'Rooms List',
  [IntlKeys.farmManagementDataRoomTypeId]: 'Room Type ID',
  [IntlKeys.farmManagementDataColor]: 'Color',
  [IntlKeys.farmManagementDataCodesPen]: 'C_Pen',
  [IntlKeys.farmManagementDataPenList]: 'Pens List',
  [IntlKeys.farmManagementDataFirstValue]: 'First Value',
  [IntlKeys.farmManagementDataSecondValue]: 'Second Value',
  [IntlKeys.farmManagementDataNumberPlaces]: 'Number Of Places',
  [IntlKeys.farmManagementDataUbn]: 'UBN',
  [IntlKeys.farmManagementDataKvk]: 'KVK',
  [IntlKeys.farmManagementDataKvkName]: 'Registration name',
  [IntlKeys.farmManagementDataCodesAccount]: 'C_Account',
  [IntlKeys.farmManagementDataAccountList]: 'Accounts List',
  [IntlKeys.farmManagementDataPercentSows]: 'Percent Sows',
  [IntlKeys.farmManagementDataPercentWeaners]: 'Percent Weaners',
  [IntlKeys.farmManagementDataPercentFinishers]: 'Percent Finishers',
  [IntlKeys.farmManagementDataPercentReplGilts]: 'Percent ReplGilts',
  [IntlKeys.farmManagementDataTypeId]: 'Acc Type ID',
  [IntlKeys.farmManagementDataActive]: 'Is Active',
  [IntlKeys.farmManagementDataEvType]: 'EV Type',
  [IntlKeys.farmManagementDataVatId]: 'VAT ID',
  [IntlKeys.farmManagementDataBarn]: 'Barn',
  [IntlKeys.farmManagementDataMedicine]: 'Medicine',
  [IntlKeys.farmManagementDataInoculation]: 'Inoculation',
  [IntlKeys.farmManagementDataHormone]: 'Hormone',
  [IntlKeys.farmManagementDataMedicineEanCode]: 'Medicine EAN Code',
  [IntlKeys.farmManagementDataMedicineCountryCode]: 'Medicine Country Code',
  [IntlKeys.farmManagementDataMedicineOfficialName]: 'Medicine Official Name',
  [IntlKeys.farmManagementDataMedicineDateEnd]: 'Medicine Date End',
  [IntlKeys.farmManagementDataMedicineUnit]: 'Medicine Unit',
  [IntlKeys.farmManagementDataMedicineWhPeriod]: 'Medicine WH Period',
  [IntlKeys.farmManagementDataMedicineDosage]: 'Medicine Dosage',
  [IntlKeys.farmManagementDataMedicineDosageFactor]: 'Medisine Dosage Factor',
  [IntlKeys.farmManagementDataSupplierId]: 'Supplier ID',
  [IntlKeys.farmManagementDataMedicineEmbCode]: 'Medicine EMB Code',
  [IntlKeys.farmManagementDataCodesEventTypes]: 'C_Event Type',
  [IntlKeys.farmManagementDataEventTypesList]: 'Event Types List',
  [IntlKeys.farmManagementDataShortcut]: 'Shortcut',
  [IntlKeys.farmManagementDataGroupExpense]: 'G_Expense',
  [IntlKeys.farmManagementDataExpenseList]: 'Expenses List',
  [IntlKeys.farmManagementDataPeriodFrom]: 'Period From',
  [IntlKeys.farmManagementDataPeriodTo]: 'Period To',

  // farm management passports

  [IntlKeys.farmManagementPassportsAddCompanyId]: 'Add Company_ID (Agrisyst)',
  [IntlKeys.farmManagementPassportsAddFarmId]: 'Add Farm_ID (Agrisyst)',
  [IntlKeys.farmManagementPassportsRemoveAlert]: 'Are you sure you want to remove this FMS? This action is irreversible',
  [IntlKeys.farmManagementPassportsRemoved]: 'FMS Removed',
  [IntlKeys.farmManagementPassportsSaved]: 'FMS Saved',

  // aliases

  [IntlKeys.aliasesDataset]: 'Dataset',
  [IntlKeys.aliasesAlias]: 'Alias',
  [IntlKeys.aliasesOtherSystem]: 'Other system',
  [IntlKeys.aliasesPrimaryDatapoint]: 'Primary datapoint',
  [IntlKeys.aliasesPrimaryUbn]: 'Primary UBN, {ubn}',
  [IntlKeys.aliasesFtpManualUpload]: 'FTP/Manual upload',
  [IntlKeys.aliasesEdiCircle]: 'EDI Circle',
  [IntlKeys.aliasesJoinData]: 'Join Data',
  [IntlKeys.aliasesUserPassportId]: 'User Passport ID',
  [IntlKeys.aliasesPassportAliasId]: 'Passport Alias ID',
  [IntlKeys.aliasesToDelete]: 'To Delete',
  [IntlKeys.aliasesDatasetName]: 'Dataset Name',
  [IntlKeys.aliasesUploadSource]: 'Upload Source',
  [IntlKeys.aliasesNotes]: 'Notes',
  [IntlKeys.aliasRemoveAlert]: 'Are you sure you want to remove this alias? This action is irreversible',
  [IntlKeys.aliasRemoved]: 'Alias Removed',
  [IntlKeys.aliasSaved]: 'Alias Saved',

  // labels

  [IntlKeys.labelsNote]: 'Note: Changes made to this label will be reflected everywhere',

  // table

  [IntlKeys.tableBodyEmptyDataSourceMessage]: 'No records om te laten zien',
  [IntlKeys.tableBodyAddTooltip]: 'Toevoegen',
  [IntlKeys.tableBodyDeleteTooltip]: 'Delete',
  [IntlKeys.tableBodyEditTooltip]: 'Bewerk',
  [IntlKeys.tableBodyFilterRowFilterTooltip]: 'Filter',
  [IntlKeys.tableBodyEditRowDeleteText]: 'Weet u zeker dat deze rij wilt verwijderen?',
  [IntlKeys.tableBodyEditRowCancelTooltip]: 'Annuleren',
  [IntlKeys.tableBodyEditRowSaveTooltip]: 'Opslaan',
  [IntlKeys.tableGroupingPlaceholder]: 'Sleep kopteksten hierheen om te groeperen',
  [IntlKeys.tableGroupingGroupedBy]: 'Gegroepeerd Door:',
  [IntlKeys.tableHeaderActions]: 'Acties',
  [IntlKeys.tablePaginationLabelDisplayedRows]: '{from}-{to} van {count}',
  [IntlKeys.tablePaginationLabelRowsSelect]: 'rijen',
  [IntlKeys.tablePaginationLabelRowsPerPage]: 'Rijen per pagina:',
  [IntlKeys.tablePaginationFirstAriaLabel]: 'Eerste pagina',
  [IntlKeys.tablePaginationFirstTooltip]: 'Eerste pagina',
  [IntlKeys.tablePaginationPreviousAriaLabel]: 'Vorige pagina',
  [IntlKeys.tablePaginationPreviousTooltip]: 'Vorige pagina',
  [IntlKeys.tablePaginationNextAriaLabel]: 'Volgende bladzijde',
  [IntlKeys.tablePaginationNextTooltip]: 'Volgende bladzijde',
  [IntlKeys.tablePaginationLastAriaLabel]: 'Laatste pagina',
  [IntlKeys.tablePaginationLastTooltip]: 'Laatste pagina',
  [IntlKeys.tableToolbarAddRemoveColumns]: 'Kolommen toevoegen of verwijderen',
  [IntlKeys.tableToolbarNRowsSelected]: '{0} rij(en) geselecteerd',
  [IntlKeys.tableToolbarShowColumnsTitle]: 'Kolommen weergeven',
  [IntlKeys.tableToolbarShowColumnsAriaLabel]: 'Kolommen weergeven',
  [IntlKeys.tableToolbarExportTitle]: 'Exporteren',
  [IntlKeys.tableToolbarExportAriaLabel]: 'Exporteren',
  [IntlKeys.tableToolbarExportName]: 'Exporteren als CSV',
  [IntlKeys.tableToolbarSearchTooltip]: 'Zoeken',
  [IntlKeys.tableToolbarSearchPlaceholder]: 'Zoeken',

  // service

  [IntlKeys.serviceCreatedAt]: 'Gemaakt op',
  [ServiceKeys.updatedAt]: 'Aangepast op',
  [IntlKeys.serviceUpdatedBy]: 'Updated by',
  [ServiceKeys.uploadedAt]: 'Geupload op',
  [ServiceKeys.uploadedBy]: 'Geupload door',
  [ServiceKeys.isEdited]: 'Aangepast',
  [ServiceKeys.link]: 'Koppeling',
  [ServiceKeys.source]: 'Bron',
  [ServiceKeys.checksum]: 'Checksum',
  [ServiceKeys.id]: 'id',
  [IntlKeys.serviceBlockchainHashKey]: 'Blockchain hash',
  [IntlKeys.serviceBlockchainTimestamp]: 'Blockchain timestamp',
  [ServiceKeys.blockchainId]: 'Blockchain ID',
  [IntlKeys.serviceBlockchainMessageId]: 'Blockchain bericht ID',
  [ServiceKeys.tableMenu]: 'Tabel menu',
  [ServiceKeys.checkbox]: 'Checkbox',
  [IntlKeys.serviceLastUpdateAt]: 'Laatst bijgewerkt op',
  [IntlKeys.serviceLastUpdateBy]: 'Last update by',

  // auth

  [IntlKeys.authSignInPassword]: 'Wachtwoord',
  [IntlKeys.authSignInLogIn]: 'Log In',
  [IntlKeys.authSignInForgotPassword]: 'Wachtwoord vergeten?',
  [IntlKeys.authSignInClickHere]: 'Klik hier om uw wachtwoord opnieuw in te stellen of aan te passen',
  // prettier-ignore
  [IntlKeys.authSignInResetPasswordMessage]: 'Voer het e-mailadres in dat u gebruikt heeft om zich te registreren op het platform en dan sturen we u een link om uw wachtwoord opnieuw in te stellen of aan te passen.',
  [IntlKeys.authSignInSendLink]: 'Stuur link',
  // prettier-ignore
  [IntlKeys.authSignInLinkSentMessage]: 'Binnen een paar minuten ontvangt u een e-mail met verdere instructies. Indien u geen e-mail ontvangt, controleer dan uw spam folder, en controleer of het ingevulde email adres correct is.',
  [IntlKeys.authPasswordEnterNewPassword]: 'Voer een nieuw wachtwoord',
  // prettier-ignore
  [IntlKeys.authPasswordNewPasswordHelpMessage]: 'Maak een nieuw wachtwoord van minimaal 8 karakters. Een sterk wachtwoord is een combinatie van letters, cijfers en karakters',
  [IntlKeys.authPasswordRestoreExpired]: 'Koppeling wachtwoord herstellen is verlopen.',
  [IntlKeys.authPasswordSuccessTitle]: 'Wachtwoord is veranderd!',
  [IntlKeys.authPasswordSuccessMessage]: 'U wordt zo meteen doorgestuurd naar de inlog pagina',
  [IntlKeys.authPasswordRepeatPassword]: 'Herhaal Wachtwoord',
  [IntlKeys.authPasswordUpdatePassword]: 'Update uw wachtwoord',
  [IntlKeys.authPasswordOldPassword]: 'Oud Wachtwoord',
  [IntlKeys.authSessionExpired]: 'Sessie is verlopen, meld u opnieuw aan.',

  [IntlKeys.serverMaintenance]: 'Er is iets misgegaan',
  [IntlKeys.maintenanceDescr]: 'We werken er aan om de service voor u te verbeteren.',
  [IntlKeys.maintenanceTryAgain]: 'Probeer het later nog eens!',

  // form validation

  [IntlKeys.validationRequired]: 'Verplicht',
  [IntlKeys.validationInvalidEmail]: 'Ongeldig e-mail',
  [IntlKeys.validationPasswordLength]: 'Moet minstens {n} karakters',
  [IntlKeys.validationPasswordsMatch]: 'Wachtwoorden moeten overeenkomen',
  [IntlKeys.validationRequiredField]: '{name} is een verplicht veld.',
  [IntlKeys.validationRequiredAtLeastOneField]: 'Tenminste één van de velden is verplicht: {field1}, {field2}.',
  [IntlKeys.validationShouldBePositive]: '{name} should be a positive number.',
  [IntlKeys.validationShouldBeInRangeBetween]: '{name} should be in a range between {min} and {max}.',
  [IntlKeys.validationShouldBeZeroOrPositive]: '{name} moet nul of een positief getal zijn.',
  [IntlKeys.notAvailableAnymore]: 'Optie niet meer beschikbaar is, kies er een uit de lijst.',

  // statuses

  [IntlKeys.statusActive]: 'Lopend',
  [IntlKeys.statusInactive]: 'Inactief',
  [IntlKeys.statusPending]: 'Uitstaand',
  [IntlKeys.statusRefused]: 'Geweigerd',
  [IntlKeys.statusRevoked]: 'Ingetrokken',
  [IntlKeys.statusGranted]: 'Goedgekeurd',
  [IntlKeys.statusExpired]: 'Verlopen',

  // carbon footprint (co2)
  [IntlKeys.carbonFootprintCo2Totals]: 'Co2_Totals',
  [IntlKeys.carbonFootprintUbn]: 'UBN',
  [IntlKeys.carbonFootprintKvk]: 'KVK',
  [IntlKeys.carbonFootprintKvkName]: 'KVK naam',
  [IntlKeys.carbonFootprintCompanyType]: 'Bedrijfstype',
  [IntlKeys.carbonFootprintCultivationAndProductionOfFeedCo2Breeder]: '1. Teelt en productie van voer CO2-eq (kg)',
  [IntlKeys.carbonFootprintTransportOfFeedCo2Breeder]: '2. Transport van voer CO2-eq (kg)',
  [IntlKeys.carbonFootprintManureStorageOfFeedCo2Breeder]: '3. Emissies mestopslag CO2-eq (kg)',
  [IntlKeys.carbonFootprintManureTransportOfFeedCo2Breeder]: '4. Emissies mesttransport CO2-eq (kg)',
  [IntlKeys.carbonFootprintEntericFermentationCo2Breeder]: '5. Enterische fermentatie CO2-eq (kg)',
  [IntlKeys.carbonFootprintEnergyUseCo2Breeder]: '6. Energieverbruik CO2-eq (kg)',
  [IntlKeys.carbonFootprintSowsCo2]: '7a. Zeugen CO2-eq (kg)',
  [IntlKeys.carbonFootprintSowsValue]: '7a. Zeugen Waarde (€)',
  [IntlKeys.carbonFootprintPigletsCo2]: '7b. Vleesvarkens CO2-eq (kg)',
  [IntlKeys.carbonFootprintPigletsValue]: '7b. Vleesvarkens Waarde (€)',
  [IntlKeys.carbonFootprintTransportPigletsCo2Fattener]: '8. Transport vleesvarken CO2-eq (kg)',
  [IntlKeys.carbonFootprintCultivationAndProductionOfFeedCo2Fattener]: '9. Teelt en productie van voer CO2-eq (kg)',
  [IntlKeys.carbonFootprintTransportOfFeedCo2Fattener]: '10. Transport van voer CO2-eq (kg)',
  [IntlKeys.carbonFootprintManureStorageOfFeedCo2Fattener]: '11. Emissies mestopslag CO2-eq (kg)',
  [IntlKeys.carbonFootprintManureTransportOfFeedCo2Fattener]: '12. Emissies mesttransport CO2-eq (kg)',
  [IntlKeys.carbonFootprintEntericFermentationCo2Fattener]: '13. Enterische fermentatie CO2-eq (kg)',
  [IntlKeys.carbonFootprintEnergyUseCo2Fattener]: '14. Energieverbruik CO2-eq (kg)',
  [IntlKeys.carbonFootprintId]: 'ID',
  [IntlKeys.carbonFootprintStatus]: 'Status',
  [IntlKeys.carbonFootprintFileName]: 'Bestandsnaam',
  [IntlKeys.carbonFootprintValidDateFrom]: 'Geldig vanaf',
  [IntlKeys.carbonFootprintValidDateTo]: 'Geldig tot',
  [IntlKeys.carbonFootprintNotes]: 'Opmerkingen',
  [IntlKeys.carbonFootprintsBreeding]: 'Zeugenhouderij',
  [IntlKeys.carbonFootprintsFattening]: 'Vleesvarkenshouderij',
  [IntlKeys.carbonFootprintsEconomicSowsAndPiglets]: 'Economische toewijzing zeugen en biggen',
  [IntlKeys.carbonFootprintsValue]: 'Waarde (€)',
  [IntlKeys.carbonFootprintsEconomicAllocation]: 'Economische toewijzing',
  [IntlKeys.carbonFootprintsCalculation]: 'Berekening',
  [IntlKeys.carbonFootprintsCo2Eq]: 'kg CO2-eq/100 g eiwit per bron',
  [IntlKeys.carbonFootprintsDistribution]: '%-verdeling',
  [IntlKeys.carbonFootprintsConcept]: 'Keten gem. per bron (%)',
  [IntlKeys.carbonFootprintsConceptAverageKg]: 'Keten gem. per bron (kg CO2-eq/100 g eiwit)',
  [IntlKeys.carbonFootprintsTotal]: 'Totaal',
  [IntlKeys.carbonFootprintsPerPiglet]: 'kg CO2-eq per big',
  [IntlKeys.carbonFootprintsCultivationAndProductionOfFeedBreeding]: '1. Teelt en productie van voer',
  [IntlKeys.carbonFootprintsTransportOfFeedBreeding]: '2. Transport van voer',
  [IntlKeys.carbonFootprintsManureStorageBreeding]: '3. Emissies mestopslag',
  [IntlKeys.carbonFootprintsManureTransportBreeding]: '4. Emissies mesttransport',
  [IntlKeys.carbonFootprintsEntericFermentationBreeding]: '5. Voerfermentatie in het dier',
  [IntlKeys.carbonFootprintsEnergyUseBreeding]: '6. Energieverbruik',
  [IntlKeys.carbonFootprintsSows]: '7a. Zeugen',
  [IntlKeys.carbonFootprintsPiglets]: '7b. Biggen',
  [IntlKeys.carbonFootprintsPigletsFattening]: '7b. Biggen (economische allocatie)',
  [IntlKeys.carbonFootprintsTransportPigletsFattening]: '8. Transport biggen',
  [IntlKeys.carbonFootprintsCultivationAndProductionOfFeedFattening]: '9. Teelt en productie van voer',
  [IntlKeys.carbonFootprintsTransportOfFeedFattening]: '10. Transport van voer',
  [IntlKeys.carbonFootprintsManureStorageFattening]: '11. Emissies mestopslag',
  [IntlKeys.carbonFootprintsManureTransportFattening]: '12. Emissies mesttransport',
  [IntlKeys.carbonFootprintsEntericFermentationFattening]: '13. Voerfermentatie in het dier',
  [IntlKeys.carbonFootprintsEnergyUseFattening]: '14. Energieverbruik',
  [IntlKeys.carbonFootprintKgSlaughterWeight]: 'kg CO2-eq/100 g eiwit',
  [IntlKeys.carbonFootprintsSowsTotal]: '7a. Zeugen (economische allocatie) – toegeschreven aan zeugenhouderij',
  [IntlKeys.carbonFootprintsPigsTotal]: '7b. Biggen (economische allocatie) – toegeschreven aan vleesvarkenshouderij',
  [IntlKeys.carbonFootprintsTotalCo2Eq]: 'Vleesvarkens CO2-eq (kg)',
  [IntlKeys.carbonFootprintsSowsCo2Eq]: 'Zeugen CO2-eq/100 g eiwit',
  [IntlKeys.carbonFootprintsPigsCo2Eq]: 'Biggen CO2-eq/100 g eiwit',
  [IntlKeys.carbonFootprintsTransportOfFeedHint]: 'INFO: Transport van voer is meegenomen in de berekening van teelt en productie voer',
  [IntlKeys.carbonFootprintsBreedingTotalCo2Eq]: 'Totaal CO2-eq (kg)',

  // request log users
  [IntlKeys.requestLogUsersFullName]: 'Gebruikersnaam',
  [IntlKeys.requestLogUsersEmail]: 'Email',
  [IntlKeys.requestLogUsersLastLogin]: 'Laatste Login',
  [IntlKeys.requestLogUsersPrefLang]: 'Voorkeurstaal',
  [IntlKeys.requestLogUsersRequestCount]: 'Weergaven',
  [IntlKeys.requestLogUsersUserPassports]: 'Gebruikerspaspoorten',
  [IntlKeys.requestLogUsersLabels]: 'Labels',
  [IntlKeys.requestLogUsersCarbonFootprintCount]: 'Weergaven CO2-Footprint',
  [IntlKeys.requestLogUsersOrganizationCount]: 'Weergaven Mijn Bedrijf',
  [IntlKeys.requestLogUsersParticipaionCount]: 'Weergaven Machtigingen',
  [IntlKeys.requestLogUsersAnimalPassportCount]: 'Weergaven Dierenpaspoort',
  [IntlKeys.requestLogUsersVriCalculatedCount]: 'Weergaven VRI Tool',
  [IntlKeys.requestLogUsersVriCount]: 'Weergaven Good Farming Star',
  [IntlKeys.requestLogUsersFarmerInputCount]: 'Weergaven Invoerportaal',
  [IntlKeys.requestLogUsersMyJoindataCount]: 'Mijn JoinData',
  [IntlKeys.requestLogUsersMyFarmCount]: 'Weergaven Bedrijfsprofiel',
  [IntlKeys.requestLogUsersHfmOutputCount]: 'CFP Scope 1&2',
  [IntlKeys.requestLogUsersVriComparisonToolCount]: 'VRI Comparison Tool',

  // user admin updates log
  [IntlKeys.userAdminUpdatesLogCreatedBy]: 'Aangemaakt door',
  [IntlKeys.userAdminUpdatesLogAction]: 'Actie',
  [IntlKeys.userAdminUpdatesLogActionType]: 'Actie Type',
  [IntlKeys.userAdminUpdatesLogLoggableType]: 'Loggable Type',

  // update logs details
  [IntlKeys.detailsPropertyName]: 'Eigenschap',
  [IntlKeys.detailsPreviousValue]: 'Vorige waarde',
  [IntlKeys.detailsNewValue]: 'Nieuwe waarde',
  [IntlKeys.detailsShowMore]: 'Toon meer',
  [IntlKeys.detailsFullName]: 'Volledige naam',
  [IntlKeys.detailsCompanies]: 'Bedrijven',
  [IntlKeys.detailsUserPassports]: 'Paspoorten van gebruikers',
  [IntlKeys.detailsPermissionProfile]: 'Permission profile',
  [IntlKeys.detailsPrefLang]: 'Voorkeurstaal',
  [IntlKeys.detailsColorTheme]: 'Kleurenthema',
  [IntlKeys.detailsIsContactPerson]: 'Is contactpersoon',
  [IntlKeys.detailsStatus]: 'Status',
  [IntlKeys.detailsKvk]: 'KVK',
  [IntlKeys.detailsKvkCompanyName]: 'KVK bedrijfsnaam',
  [IntlKeys.detailsEmail]: 'Email',
  [IntlKeys.detailsCustomKvkName]: 'Aangepaste KVK naam',
  [IntlKeys.detailsUbn]: 'UBN',
  [IntlKeys.detailsGln]: 'GLN',
  [IntlKeys.detailsVionRelationNumber]: 'Klantrelatienummer',
  [IntlKeys.detailsBreedingProgram]: 'Fokprogramma',
  [IntlKeys.detailsCertificates]: 'Certificaten',
  [IntlKeys.detailsCertificatesOther]: 'Certificaten overig',
  [IntlKeys.detailsClimateSystemOther]: 'Klimaatsysteem overig',
  [IntlKeys.detailsClimateSystems]: 'Klimaatsystemen',
  [IntlKeys.detailsConcepts]: 'Concepten',
  [IntlKeys.detailsCustomUbnName]: 'Aangepaste UBN-naam',
  [IntlKeys.detailsFeedSupplierOther]: 'Feed supplier other',
  [IntlKeys.detailsFeedSuppliers]: 'Voerleveranciers',
  [IntlKeys.detailsFeedingSystemOther]: 'Voersysteem overig',
  [IntlKeys.detailsFeedingSystems]: 'Voersystemen',
  [IntlKeys.detailsConcepts]: 'Concepten',
  [IntlKeys.detailsCustomUbnName]: 'Aangepaste UBN-naam',
  [IntlKeys.detailsFeedSupplierOther]: 'Feed supplier other',
  [IntlKeys.detailsFeedSuppliers]: 'Voerleveranciers',
  [IntlKeys.detailsFeedingSystemOther]: 'Voersysteem overig',
  [IntlKeys.detailsFeedingSystems]: 'Voersystemen',
  [IntlKeys.detailsFmsModuleAgrisysts]: 'FMS module agrisysts',
  [IntlKeys.detailsFmsModuleAgroCoops]: 'FMS module agroscoops',
  [IntlKeys.detailsFmsModuleAgrovisions]: 'FMS module agrovisions',
  [IntlKeys.detailsFmsNames]: 'FMS namen',
  [IntlKeys.detailsGeneticSupplierOther]: 'Genetische leverancier overig',
  [IntlKeys.detailsGeneticSuppliers]: 'Genetische leveranciers',
  [IntlKeys.detailsKiStationOther]: 'KI station overig',
  [IntlKeys.detailsKiStations]: 'KI-stations',
  [IntlKeys.detailsPhone]: 'Telefoon',
  [IntlKeys.detailsPlace]: 'Plaats',
  [IntlKeys.detailsPostcode]: 'Postcode',
  [IntlKeys.detailsSireLineOther]: 'Vaderlijn overig',
  [IntlKeys.detailsSireLines]: 'Vaderlijnen',
  [IntlKeys.detailsSowLines]: 'Zeugenlijnen',
  [IntlKeys.detailsSowLineOther]: 'Zeugenlijn overig',
  [IntlKeys.detailsStreet]: 'Straat',
  [IntlKeys.detailsTypeOfCompany]: 'Type bedrijf',
  [IntlKeys.detailsVatNr]: 'BTW-nummer',
  [IntlKeys.detailsVeterinarian]: 'Dierenarts',
  [IntlKeys.detailsVeterinaryPractice]: 'Dierenartsenpraktijk',
  [IntlKeys.detailsVeterinaryPracticeOther]: 'Dierenartsenpraktijk overig',
  [IntlKeys.password]: 'Wachtwoord',
  [IntlKeys.confirmPassword]: 'Bevestig wachtwoord',
  [IntlKeys.acceptInvite]: 'Accepteer uitnodiging',

  // raw materials origin
  [IntlKeys.rawMaterialsOriginSource]: 'Source',
  [IntlKeys.rawMaterialsOriginMessageId]: 'Message ID',
  [IntlKeys.rawMaterialsOriginSupplierNumber]: 'Leveranciers Nummer',
  [IntlKeys.rawMaterialsOriginSupplierName]: 'Leveranciers Naam',
  [IntlKeys.rawMaterialsOriginCustomerNumber]: 'Klant Nummer',
  [IntlKeys.rawMaterialsOriginCustomerName]: 'Klant Naam',
  [IntlKeys.rawMaterialsOriginUbn]: 'UBN Nummer',
  [IntlKeys.rawMaterialsOriginBeginningTime]: 'Begin Tijd',
  [IntlKeys.rawMaterialsOriginEndTime]: 'Eind Tijd',
  [IntlKeys.rawMaterialsOriginAnimalCategoryCode]: 'Dier categorie Code',
  [IntlKeys.rawMaterialsOriginSubAnimalCategoryCode]: 'Sub Dier categorie Code',
  [IntlKeys.rawMaterialsOriginArticleNumber]: 'Artikel Nummer',
  [IntlKeys.rawMaterialsOriginArticleTypeNumberCode]: 'Type Artikel Nummer Code',
  [IntlKeys.rawMaterialsOriginArticleDescription]: 'Leveranciers Nummer',
  [IntlKeys.rawMaterialsOriginNetQuantityDeliveredProduct]: 'Netto Hoeveelheid Geleverd Product',
  [IntlKeys.rawMaterialsOriginQuantityUnit]: 'Hoeveelheid Eenheid',
  [IntlKeys.rawMaterialsOriginNutrientDs]: 'Nutrient-DS',
  [IntlKeys.rawMaterialsOriginNutrientRe]: 'Nutrient-RE',
  [IntlKeys.rawMaterialsOriginBreed]: 'RAS',
  [IntlKeys.rawMaterialsOriginNutrientVcre]: 'Nutrient-VCRE',
  [IntlKeys.rawMaterialsOriginNutrientVcos]: 'Nutrient-VCOS',
  [IntlKeys.rawMaterialsOriginRegionOfOriginCode]: 'Regio Van Herkomst Code',
  [IntlKeys.rawMaterialsOriginCreatedAt]: 'Upload Datum',

  // hfm output
  [IntlKeys.hfmOutputId]: 'ID',
  [IntlKeys.hfmOutputTitle]: 'HFM Output',
  [IntlKeys.hfmOutputYear]: 'Jaar',
  [IntlKeys.hfmOutputPeriod]: 'Periode',
  [IntlKeys.hfmOutputQuarter]: 'Quarter',
  [IntlKeys.hfmOutputYearToDate]: 'Year to date',
  [IntlKeys.hfmOutputEntityExternalId]: 'Entiteit',
  [IntlKeys.hfmOutputAccountHfm]: 'Account HFM',
  [IntlKeys.hfmOutputExtraExternalId]: 'Custom 1',
  [IntlKeys.hfmOutputConsumption]: 'Verbruik',
  [IntlKeys.hfmOutputCo2Equivalent]: 'kg CO2 equivalent',
  [IntlKeys.hfmOutputEntities]: 'Entities',
  [IntlKeys.hfmOutputEntitiesCountry]: 'Country',
  [IntlKeys.hfmOutputEntitiesBusinessUnit]: 'Business Unit',
  [IntlKeys.hfmOutputGreenhouseConversionFactor]: 'Conversion Factor',
  [IntlKeys.hfmOutputGreenhouseEmissionFactor]: 'Emission Factor',
  [IntlKeys.hfmOutputGreenhouseCategory]: 'Category',
  [IntlKeys.hfmOutputGreenhouseName]: 'Name',
  //
  [IntlKeys.hfmOutputReportName]: 'Rapportnaam',
  [IntlKeys.hfmOutputNa]: 'N.A.',
  [IntlKeys.hfmOutputIcp]: 'ICP',
  [IntlKeys.hfmOutputCustom2]: 'Custom 2',
  [IntlKeys.hfmOutputCustom3]: 'Custom 3',
  [IntlKeys.hfmOutputCustom4]: 'Custom 4',

  // errors log
  [IntlKeys.errorsLogLogDatetime]: 'Log datetime',
  [IntlKeys.errorsLogErrorLogType]: 'Error log type',
  [IntlKeys.errorsLogErrorBody]: 'Error body',
  [IntlKeys.errorsLogErrorCode]: 'Error code',

  // sign in messages
  [IntlKeys.incorrectCredentials]: 'Ongeldige logingegevens.',
  [IntlKeys.incorrectCredentialsExtended]: 'Ongeldige aanmeldingsgegevens. Probeer het opnieuw.',
  [IntlKeys.accountIsSuspended]: 'Uw account is geblokkeerd, neem contact op met powerchain@impactbuying.com',
  [IntlKeys.passwordExpired]: 'Uw wachtwoord is verlopen! Verander het alstublieft.',
  [IntlKeys.sessionExpired]: 'Uw sessie is verlopen! Meld u opnieuw aan.',
  [IntlKeys.incorrectCredentialsNl]: 'Ongeldige logingegevens.',
  [IntlKeys.incorrectCredentialsNlExtended]: 'Ongeldige aanmeldingsgegevens. Probeer het opnieuw.',
  [IntlKeys.accountIsSuspendedNl]: 'Uw account is geblokkeerd, neem contact op met powerchain@impactbuying.com',
  [IntlKeys.passwordExpiredNl]: 'Uw wachtwoord is verlopen! Verander het alstublieft.',
  [IntlKeys.sessionExpiredNl]: 'Uw sessie is verlopen! Meld u opnieuw aan.'
};
