import { call, takeEvery } from 'redux-saga/effects';
import { AxiosError } from 'axios';
import { serialize as objectToFormData } from 'object-to-formdata';
////////////////////////////////////////////////////////////////////////////////////
import ensure, { ApiCallTransformers, Ensure } from 'lib/ensure';
import { ApiClient } from 'lib/http/ApiClient';
import { CustomAction } from 'lib/actions';
import { stringifyMultipleItemsErrors } from 'lib/http/errors';
import { ValidationError } from 'lib/excel/validators/errors';

import { PERMISSION_PROFILE_TEMPLATES } from 'lib/tables/config/admin/permission/profileTemplates/config';

import { serializeAdminUser } from 'store/entities/admin/users';
import { serializeUbnRelatedImpersonalUsers } from 'store/entities/admin/organization/impersonalUbnData';
import { serializeUbnData } from 'store/entities/admin/organization/ubnDataIndex';

import {
  acceptNewUserInviteAction,
  changeUserStatusAction,
  createOrganizationAction,
  deleteOrgAction,
  deleteUserAction,
  inviteNewUserAction,
  updateAdminUserAction,
  uploadAdminOrgPassportUsersAction,
  uploadAdminOrganizationAction,
  uploadAdminUsersAction,
  createUserPassportAction,
  adminUpdateUserPassportAction,
  userUpdateCustomUbnNameAction,
  userCreateCompanyReport,
  userUpdateCompanyReport,
  userCloneCompanyReport,
  userDeleteCompanyReport,
  userCreateByProductReport,
  userUpdateByProductReport,
  userCloneByProductReport,
  userDeleteByProductReport,
  userCreateFermentationReport,
  userUpdateFermentationReport,
  userCloneFermentationReport,
  userDeleteFermentationReport,
  userCreateManureReport,
  userUpdateManureReport,
  userCloneManureReport,
  userDeleteManureReport,
  deleteUserPassportAction,
  getEntityListAction,
  createPermissionProfileTemplateAction,
  getPermissionProfileTemplateAction,
  updatePermissionProfileTemplateAction,
  updateOrganizationPermissionProfileAction,
  uploadUserPassportsAction,
  getPermissionProfileTemplatesAction,
  updateOrganizationAction,
  deletePermissionProfileTemplateAction,
  getUbnRelatedImpersonalUsersAction,
  adminGetUbnRelatedUserPassportsAction,
  getExistingUserTypesAction,
  reinviteUserAction,
  getOrgPassportOptionsAction,
  getDefaultOrgPassportOptionsAction,
  updateOrganizationByUserAction,
  updateManageUploadAction,
  downloadManageUploadAction,
  deleteManageUploadAction,
  downloadFarmManagementFtpUpdatesAction,
  deleteFarmManagementFtpUpdatesAction,
  createFarmManagementPassportAction,
  updateFarmManagementPassportAction,
  deleteFarmManagementPassportAction,
  createAliasAction,
  updateAliasAction,
  deleteAliasAction,
  deleteCarbonFootprintAction,
  updateCarbonFootprintAction,
  uploadAliasesAction,
  createLabelAction,
  userCreateLabelAction,
  updateLabelAction,
  userUpdateLabelAction,
  deleteLabelAction,
  userDeleteLabelAction,
  deleteHFMOutputAction,
  updateHFMOutputAction,
  fetchPermissionTemplates,
  userUpdateUserPassportAction, getLastChangesAction
} from './reducer';
import { serializePermissionProfileTemplates } from './entities';
import { serializeOrgPassportOptions } from 'store/entities/admin/orgPassportOptions';

const organizationUpdateTransformRequest: ApiCallTransformers['transformRequestData'] = (data) => {
  return objectToFormData(data);
};

const ensureCreateOrganization = ensure({
  api: ApiClient.adminCreateOrganization,
  action: createOrganizationAction,
  transformRequestData: organizationUpdateTransformRequest,
});

const ensureUpdateOrganization = ensure({
  api: ApiClient.adminUpdateOrganization,
  action: updateOrganizationAction,
  transformRequestData: organizationUpdateTransformRequest,
});
const ensureUserUpdateOrganization = ensure({
  api: ApiClient.updateOrganization,
  action: updateOrganizationByUserAction,
  transformRequestData: organizationUpdateTransformRequest,
});

const ensureUpdateOrganizationPermissionProfile = ensure({
  api: ApiClient.adminUpdateOrganizationPermissionProfile,
  action: updateOrganizationPermissionProfileAction,
});

const ensureInviteNewUser = ensure({
  api: ApiClient.inviteUser,
  action: inviteNewUserAction,
});

const ensureAcceptNewUserInvitation = ensure({
  api: ApiClient.acceptInvitation,
  action: acceptNewUserInviteAction,
});

const ensureChangeAdminUserStatus = ensure({
  api: ApiClient.changeAdminUserStatus,
  action: changeUserStatusAction,
  serializeSuccessPayload: serializeAdminUser,
});

const ensureDeleteAdminUser = ensure({
  api: ApiClient.deleteAdminUser,
  action: deleteUserAction,
});

const ensureUpdateAdminUser = ensure({
  api: ApiClient.updateAdminUser,
  action: updateAdminUserAction,
});

const ensureDeleteOrganization = ensure({
  api: ApiClient.adminDeleteOrganization,
  action: deleteOrgAction,
});

const ensureCreateUserPassport = ensure({
  api: ApiClient.createAdminUserPassport,
  action: createUserPassportAction,
});

const ensureAdminUpdateUserPassport = ensure({
  api: ApiClient.updateAdminUserPassport,
  action: adminUpdateUserPassportAction,
});

const ensureUserUpdateUserPassport = ensure({
  api: ApiClient.updateUserUserPassport,
  action: userUpdateUserPassportAction,
});

const ensureUpdateUserPassportUbnCustomName = ensure({
  api: ApiClient.updateUserPassportUbnCustomName,
  action: userUpdateCustomUbnNameAction,
});

const ensureCreateCompanyReport = ensure({
  api: ApiClient.createCompanyReport,
  action: userCreateCompanyReport,
});

const ensureUpdateCompanyReport = ensure({
  api: ApiClient.updateCompanyReport,
  action: userUpdateCompanyReport,
});

const ensureCloneCompanyReport = ensure({
  api: ApiClient.cloneCompanyReport,
  action: userCloneCompanyReport,
});

const ensureDeleteCompanyReport = ensure({
  api: ApiClient.deleteCompanyReport,
  action: userDeleteCompanyReport,
});

const ensureCreateByProductReport = ensure({
  api: ApiClient.createByProductReport,
  action: userCreateByProductReport,
});

const ensureUpdateByProductReport = ensure({
  api: ApiClient.updateByProductReport,
  action: userUpdateByProductReport,
});

const ensureCloneByProductReport = ensure({
  api: ApiClient.cloneByProductReport,
  action: userCloneByProductReport,
});

const ensureDeleteByProductReport = ensure({
  api: ApiClient.deleteByProductReport,
  action: userDeleteByProductReport,
});

const ensureCreateFermentationReport = ensure({
  api: ApiClient.createFermentationReport,
  action: userCreateFermentationReport,
});

const ensureUpdateFermentationReport = ensure({
  api: ApiClient.updateFermentationReport,
  action: userUpdateFermentationReport,
});

const ensureCloneFermentationReport = ensure({
  api: ApiClient.cloneFermentationReport,
  action: userCloneFermentationReport,
});

const ensureDeleteFermentationReport = ensure({
  api: ApiClient.deleteFermentationReport,
  action: userDeleteFermentationReport,
});

const ensureCreateManureReport = ensure({
  api: ApiClient.createManureReport,
  action: userCreateManureReport,
});

const ensureUpdateManureReport = ensure({
  api: ApiClient.updateManureReport,
  action: userUpdateManureReport,
});

const ensureCloneManureReport = ensure({
  api: ApiClient.cloneManureReport,
  action: userCloneManureReport,
});

const ensureDeleteManureReport = ensure({
  api: ApiClient.deleteManureReport,
  action: userDeleteManureReport,
});

const ensureDeleteUserPassport = ensure({
  api: ApiClient.deleteAdminUserPassport,
  action: deleteUserPassportAction,
});

export const transformUploadErrors = (error: AxiosError) => {
  if (error?.response?.status == 409) {
    return stringifyMultipleItemsErrors(error as any)
      .map((errors) => errors.map((error) => new ValidationError(error)))
      .flat(Number.MAX_SAFE_INTEGER);
  } else if (error?.response?.status == 422) {
    const errors = {
      ...error,
      response: {
        ...error?.response,
        data: {
          ...(error?.response?.data as any),
        },
      },
    };
    if (error?.response?.data && (error?.response?.data as any).errors) {
      const record_errors = error?.response?.data;
      if (record_errors && Array.isArray(record_errors)) {
        errors.response.data.record_errors = record_errors;
      }
    }
    const res = stringifyMultipleItemsErrors(errors);
    let errs;
    if (res) {
      errs = res.map((errors) => errors.map((error) => new ValidationError(error)));
    } else {
      errs = [new ValidationError((error?.response?.data as any).errors)]
    }
    errs = errs.flat(Number.MAX_SAFE_INTEGER);
    return errs;
  } else {
    return {};
  }
};

export const ensureAdminOrgPassportUsersUpload = ensure({
  api: ApiClient.adminUploadOrgPassportUsers,
  action: uploadAdminOrgPassportUsersAction,
  transformRequestData: ({ resources, file }) => objectToFormData({ file, resources }),
  serializeFailurePayload: transformUploadErrors,
});

export const ensureAdminOrganizationsUpload = ensure({
  api: ApiClient.adminUploadOrganizations,
  action: uploadAdminOrganizationAction,
  transformRequestData: ({ resources, file }) => objectToFormData({ file, resources }),
  serializeFailurePayload: transformUploadErrors,
});

export const ensureAdminUsersUpload = ensure({
  api: ApiClient.adminUploadUsers,
  action: uploadAdminUsersAction,
  transformRequestData: ({ resources, file }) => objectToFormData({ file, resources }),
  serializeFailurePayload: transformUploadErrors,
});

export const ensureUserPassportsUpload = ensure({
  api: ApiClient.adminUploadUserPassports,
  action: uploadUserPassportsAction,
  transformRequestData: ({ resources, file }) => objectToFormData({ file, resources }),
  serializeFailurePayload: transformUploadErrors,
});

export const ensureGetEntityList = ensure({
  api: ApiClient.adminPermissionRules,
  action: getEntityListAction,
});

export const ensureCreatePermissionProfileTemplate = ensure({
  api: ApiClient.adminCreatePermissionProfileTemplates,
  action: createPermissionProfileTemplateAction,
});

export const ensureUpdatePermissionProfileTemplate = ensure({
  api: ApiClient.adminUpdatePermissionProfileTemplates,
  action: updatePermissionProfileTemplateAction,
});

export const ensureDeletePermissionProfileTemplate = ensure({
  api: ApiClient.adminDeletePermissionProfileTemplates,
  action: deletePermissionProfileTemplateAction,
});

export const ensureGetPermissionProfileTemplate = ensure({
  api: ApiClient.adminGetPermissionProfileTemplate,
  action: getPermissionProfileTemplateAction,
});

export const ensureGetUserTypesList = ensure({
  api: ApiClient.adminUserTypesList,
  action: getExistingUserTypesAction,
});

export const ensureGetPermissionProfileTemplates = ensure({
  api: ApiClient.adminPermissionProfileTemplates,
  action: getPermissionProfileTemplatesAction,
  serializeSuccessPayload: (response) => {
    const documentData = serializePermissionProfileTemplates(response);
    const key = PERMISSION_PROFILE_TEMPLATES.PermissionProfileTemplates.unserialized;

    return documentData[key];
  },
});

export const ensureReinviteUser = ensure({
  api: ApiClient.authReinviteUser,
  action: reinviteUserAction,
});

export const ensureGetOrgPassportOptions = ensure({
  api: ApiClient.adminOrgPassportOptions,
  action: getOrgPassportOptionsAction,
  serializeSuccessPayload: serializeOrgPassportOptions,
});

export const ensureGetDefaultOrgPassportOptions = ensure({
  api: ApiClient.adminDefaultOrgPassportOptions,
  action: getDefaultOrgPassportOptionsAction,
  serializeSuccessPayload: serializeOrgPassportOptions,
});

export const ensureGetLastChanges = ensure({
  api: ApiClient.getLastChanges,
  action: getLastChangesAction,
});

const ensureAdminUpdateManageUpload = ensure({
  api: ApiClient.adminUpdateManageUpload,
  action: updateManageUploadAction,
});

const ensureAdminDownloadManageUpload = ensure({
  api: ApiClient.adminDownloadManageUpload,
  action: downloadManageUploadAction,
});

const ensureAdminDeleteManageUpload = ensure({
  api: ApiClient.adminDeleteManageUpload,
  action: deleteManageUploadAction,
});

const ensureAdminGetUbnRelatedUserPassports = ensure({
  api: ApiClient.adminUserPassportUbnRelated,
  action: adminGetUbnRelatedUserPassportsAction,
  serializeSuccessPayload: serializeUbnData,
});

const ensureGetUbnRelatedImpersonalUsers = ensure({
  api: ApiClient.getUbnRelatedImpersonalUsers,
  action: getUbnRelatedImpersonalUsersAction,
  serializeSuccessPayload: serializeUbnRelatedImpersonalUsers,
});

const ensureAdminDownloadFarmManagementFtpUpdates = ensure({
  api: ApiClient.adminDownloadFarmManagementFtpUpdates,
  action: downloadFarmManagementFtpUpdatesAction,
});

const ensureAdminDeleteFarmManagementFtpUpdates = ensure({
  api: ApiClient.adminDeleteFarmManagementFtpUpdates,
  action: deleteFarmManagementFtpUpdatesAction,
});

const ensureAdminCreateFarmManagementPassport = ensure({
  api: ApiClient.adminCreateFarmManagementPassport,
  action: createFarmManagementPassportAction,
});

const ensureAdminUpdateFarmManagementPassport = ensure({
  api: ApiClient.adminUpdateFarmManagementPassport,
  action: updateFarmManagementPassportAction,
});

const ensureAdminDeleteFarmManagementPassport = ensure({
  api: ApiClient.adminDeleteFarmManagementPassport,
  action: deleteFarmManagementPassportAction,
});

const ensureAdminCreateAlias = ensure({
  api: ApiClient.adminCreatePassportAlias,
  action: createAliasAction,
});

const ensureAdminUpdateAlias = ensure({
  api: ApiClient.adminUpdatePassportAlias,
  action: updateAliasAction,
});

const ensureAdminDeleteAlias = ensure({
  api: ApiClient.adminDeletePassportAlias,
  action: deleteAliasAction,
});

const ensureAdminDeleteCarbonFootprint = ensure({
  api: ApiClient.adminDeleteCarbonFootprint,
  action: deleteCarbonFootprintAction,
});

const ensureAdminUpdateCarbonFootprint = ensure({
  api: ApiClient.adminUpdateCarbonFootprint,
  action: updateCarbonFootprintAction,
});

const ensureAdminDeleteHFMOutput = ensure({
  api: ApiClient.adminDeleteHFMOutput,
  action: deleteHFMOutputAction,
});

const ensureAdminUpdateHFMOutput = ensure({
  api: ApiClient.adminUpdateHFMOutput,
  action: updateHFMOutputAction,
});

const ensureAdminCreateLabel = ensure({
  api: ApiClient.adminCreateLabel,
  action: createLabelAction,
});

const ensureCreateLabel = ensure({
  api: ApiClient.createLabel,
  action: userCreateLabelAction,
});

const ensureAdminUpdateLabel = ensure({
  api: ApiClient.adminUpdateLabel,
  action: updateLabelAction,
});

const ensureUpdateLabel = ensure({
  api: ApiClient.updateLabel,
  action: userUpdateLabelAction,
});

const ensureAdminDeleteLabel = ensure({
  api: ApiClient.adminDeleteLabel,
  action: deleteLabelAction,
});

const ensureDeleteLabel = ensure({
  api: ApiClient.deleteLabel,
  action: userDeleteLabelAction,
});

const ensurePermissionTemplates = ensure({
  api: ApiClient.adminPermissionProfileTemplates,
  action: fetchPermissionTemplates,
});

export const transformUploadAliasesErrors = (error: AxiosError) => {
  if (error?.response?.status == 422) {
    const errors = {
      ...error,
      response: {
        ...error?.response,
        data: { ...(error?.response?.data as any), record_errors: error?.response?.data && (error?.response?.data as any).errors || error?.response?.data && (error?.response?.data as any).record_errors },
      },
    };

    return stringifyMultipleItemsErrors(errors)
      .map((errors) => errors.map((error) => new ValidationError(error)))
      .flat(Number.MAX_SAFE_INTEGER);
  } else {
    return {};
  }
};

export const ensureAdminAliasesUpload = ensure({
  api: ApiClient.adminUploadPassportAliases,
  action: uploadAliasesAction,
  transformRequestData: ({ file }) =>
    objectToFormData({
      resource: {
        file,
      },
    }),
  responseConfigData: ({ resources }) => {
    const dataObject = {
      resources,
    };
    return { resConfigData: JSON.stringify(dataObject), saveConfigData: true };
  },
  serializeFailurePayload: transformUploadAliasesErrors,
  showToast: false
});

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

const callEnsure = (ensure: Ensure) =>
  function* ({ payload }: CustomAction) {
    yield call(ensure, payload);
  };

export default function* () {
  //
  yield takeEvery(createOrganizationAction.request, callEnsure(ensureCreateOrganization));
  yield takeEvery(updateOrganizationAction.request, callEnsure(ensureUpdateOrganization));
  yield takeEvery(updateOrganizationByUserAction.request, callEnsure(ensureUserUpdateOrganization));
  yield takeEvery(
    updateOrganizationPermissionProfileAction.request,
    callEnsure(ensureUpdateOrganizationPermissionProfile),
  );
  yield takeEvery(inviteNewUserAction.request, callEnsure(ensureInviteNewUser));
  yield takeEvery(acceptNewUserInviteAction.request, callEnsure(ensureAcceptNewUserInvitation));
  yield takeEvery(changeUserStatusAction.request, callEnsure(ensureChangeAdminUserStatus));
  yield takeEvery(deleteUserAction.request, callEnsure(ensureDeleteAdminUser));
  yield takeEvery(updateAdminUserAction.request, callEnsure(ensureUpdateAdminUser));
  yield takeEvery(uploadAdminOrgPassportUsersAction.request, callEnsure(ensureAdminOrgPassportUsersUpload));
  yield takeEvery(uploadAdminOrganizationAction.request, callEnsure(ensureAdminOrganizationsUpload));
  yield takeEvery(uploadAdminUsersAction.request, callEnsure(ensureAdminUsersUpload));
  yield takeEvery(uploadUserPassportsAction.request, callEnsure(ensureUserPassportsUpload));
  yield takeEvery(deleteOrgAction.request, callEnsure(ensureDeleteOrganization));
  yield takeEvery(createUserPassportAction.request, callEnsure(ensureCreateUserPassport));
  yield takeEvery(adminUpdateUserPassportAction.request, callEnsure(ensureAdminUpdateUserPassport));
  yield takeEvery(userUpdateUserPassportAction.request, callEnsure(ensureUserUpdateUserPassport));
  yield takeEvery(userUpdateCustomUbnNameAction.request, callEnsure(ensureUpdateUserPassportUbnCustomName));
  yield takeEvery(userCreateCompanyReport.request, callEnsure(ensureCreateCompanyReport));
  yield takeEvery(userUpdateCompanyReport.request, callEnsure(ensureUpdateCompanyReport));
  yield takeEvery(userCloneCompanyReport.request, callEnsure(ensureCloneCompanyReport));
  yield takeEvery(userDeleteCompanyReport.request, callEnsure(ensureDeleteCompanyReport));
  yield takeEvery(userCreateByProductReport.request, callEnsure(ensureCreateByProductReport));
  yield takeEvery(userUpdateByProductReport.request, callEnsure(ensureUpdateByProductReport));
  yield takeEvery(userCloneByProductReport.request, callEnsure(ensureCloneByProductReport));
  yield takeEvery(userDeleteByProductReport.request, callEnsure(ensureDeleteByProductReport));
  yield takeEvery(userCreateFermentationReport.request, callEnsure(ensureCreateFermentationReport));
  yield takeEvery(userUpdateFermentationReport.request, callEnsure(ensureUpdateFermentationReport));
  yield takeEvery(userCloneFermentationReport.request, callEnsure(ensureCloneFermentationReport));
  yield takeEvery(userDeleteFermentationReport.request, callEnsure(ensureDeleteFermentationReport));
  yield takeEvery(userCreateManureReport.request, callEnsure(ensureCreateManureReport));
  yield takeEvery(userUpdateManureReport.request, callEnsure(ensureUpdateManureReport));
  yield takeEvery(userCloneManureReport.request, callEnsure(ensureCloneManureReport));
  yield takeEvery(userDeleteManureReport.request, callEnsure(ensureDeleteManureReport));
  yield takeEvery(deleteUserPassportAction.request, callEnsure(ensureDeleteUserPassport));
  yield takeEvery(getEntityListAction.request, callEnsure(ensureGetEntityList));
  yield takeEvery(createPermissionProfileTemplateAction.request, callEnsure(ensureCreatePermissionProfileTemplate));
  yield takeEvery(getPermissionProfileTemplateAction.request, callEnsure(ensureGetPermissionProfileTemplate));
  yield takeEvery(getPermissionProfileTemplatesAction.request, callEnsure(ensureGetPermissionProfileTemplates));
  yield takeEvery(updatePermissionProfileTemplateAction.request, callEnsure(ensureUpdatePermissionProfileTemplate));
  yield takeEvery(deletePermissionProfileTemplateAction.request, callEnsure(ensureDeletePermissionProfileTemplate));
  yield takeEvery(adminGetUbnRelatedUserPassportsAction.request, callEnsure(ensureAdminGetUbnRelatedUserPassports));
  yield takeEvery(getUbnRelatedImpersonalUsersAction.request, callEnsure(ensureGetUbnRelatedImpersonalUsers));
  yield takeEvery(getExistingUserTypesAction.request, callEnsure(ensureGetUserTypesList));
  yield takeEvery(reinviteUserAction.request, callEnsure(ensureReinviteUser));
  yield takeEvery(getOrgPassportOptionsAction.request, callEnsure(ensureGetOrgPassportOptions));
  yield takeEvery(getDefaultOrgPassportOptionsAction.request, callEnsure(ensureGetDefaultOrgPassportOptions));
  yield takeEvery(getLastChangesAction.request, callEnsure(ensureGetLastChanges));
  yield takeEvery(updateManageUploadAction.request, callEnsure(ensureAdminUpdateManageUpload));
  yield takeEvery(downloadManageUploadAction.request, callEnsure(ensureAdminDownloadManageUpload));
  yield takeEvery(deleteManageUploadAction.request, callEnsure(ensureAdminDeleteManageUpload));
  yield takeEvery(
    downloadFarmManagementFtpUpdatesAction.request,
    callEnsure(ensureAdminDownloadFarmManagementFtpUpdates),
  );
  yield takeEvery(deleteFarmManagementFtpUpdatesAction.request, callEnsure(ensureAdminDeleteFarmManagementFtpUpdates));
  yield takeEvery(createFarmManagementPassportAction.request, callEnsure(ensureAdminCreateFarmManagementPassport));
  yield takeEvery(updateFarmManagementPassportAction.request, callEnsure(ensureAdminUpdateFarmManagementPassport));
  yield takeEvery(deleteFarmManagementPassportAction.request, callEnsure(ensureAdminDeleteFarmManagementPassport));
  yield takeEvery(createAliasAction.request, callEnsure(ensureAdminCreateAlias));
  yield takeEvery(updateAliasAction.request, callEnsure(ensureAdminUpdateAlias));
  yield takeEvery(deleteAliasAction.request, callEnsure(ensureAdminDeleteAlias));
  yield takeEvery(deleteCarbonFootprintAction.request, callEnsure(ensureAdminDeleteCarbonFootprint));
  yield takeEvery(updateCarbonFootprintAction.request, callEnsure(ensureAdminUpdateCarbonFootprint));
  yield takeEvery(uploadAliasesAction.request, callEnsure(ensureAdminAliasesUpload));
  yield takeEvery(createLabelAction.request, callEnsure(ensureAdminCreateLabel));
  yield takeEvery(userCreateLabelAction.request, callEnsure(ensureCreateLabel));
  yield takeEvery(updateLabelAction.request, callEnsure(ensureAdminUpdateLabel));
  yield takeEvery(userUpdateLabelAction.request, callEnsure(ensureUpdateLabel));
  yield takeEvery(deleteLabelAction.request, callEnsure(ensureAdminDeleteLabel));
  yield takeEvery(userDeleteLabelAction.request, callEnsure(ensureDeleteLabel));
  yield takeEvery(deleteHFMOutputAction.request, callEnsure(ensureAdminDeleteHFMOutput));
  yield takeEvery(updateHFMOutputAction.request, callEnsure(ensureAdminUpdateHFMOutput));
  yield takeEvery(fetchPermissionTemplates.request, callEnsure(ensurePermissionTemplates));
}
